import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
class DropMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      menuList: []
    }
  }
  
  componentDidMount() {
    this.setState({
      menuList: this.props.list
    })
  }

  renderItem() {
    let list = this.state.menuList
    return list.map((item, index) => {
      if(item.href) {
        return <a key={index} href={item.href} target="_blank" rel="noopener noreferrer">{item.title}</a>
      }
      return <Link key={index} to={item.link}>{item.title}</Link>
    })
  }
  render() {
    return <div id="drop-menu">
      {this.renderItem()}
    </div>
  }
}
export default DropMenu