import React, { Component } from 'react'
import { message } from 'antd'
import store from '../../../redux/store'
class Handle extends Component {
  componentDidMount() {
    window.open('https://oa.cscac.net/app/user/login');
    window.history.back()
    // message.info('暂未开放！敬请期待！', 3)
    // store.dispatch({
    //   type: 'CHANGE_NAV',
    //   payload: '仲裁员在线办案'
    // })
  }
  render() {
    return <div></div>
  }
}
export default Handle