import React, { Component } from 'react';
import Details from '../../Details'
import { Spin, message } from 'antd';
import store from '../../../redux/store'
class Contracts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      time: '',
      content: '',
      loading: true,
      source: ''
    }
  }
  getData() {
    fetch('/zhongcai/information/?pid=2&sid=20', {//通过pid以及sid获取到当前类目的id  
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      if(res.data.pageInfo.list.length !== 0) {
        let id = res.data.pageInfo.list[0].id
        id && fetch('/zhongcai/information/' + id, {
          method:"GET",
          headers: new Headers({
            'requestType': 'official_website'
          })
        }).then((res)=>{
          return res.json();
        }).then(res => {
          let data = res.data.information;
         
          data.content =  res.data.information.content.replace('【示范合同】','【示范合同】<span id="contract-container"></span>');
     
          if(data.shows === 1) {
            message.error("该数据已被管理员修改为不展示！", 5);
            this.setState({
              loading: false
            })
            return false
          }
          fetch(`/zhongcai/enclosure/{id}?sid=${data.secondaryId}&pid=${data.primaryId}`, {
            method:"GET",
            headers: new Headers({
              'requestType': 'official_website'
            })
          }).then((res)=>{
            if(res.status >= 500) {
              message.error('服务器出错，请稍后重试', 3)
              return false
            }
            return res.json();
          }).then(res => {
            if(!res) {
              return false
            }
            store.dispatch({
              type: 'ADD_DOWN_LIST',
              payload: res.list
            })
          })
          this.setState({
            title: '合同下载',
            time: data.createat,
            content: data.content,
            loading: false
          })
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    this.getData()
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '合同下载'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
  }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id='down-file' ref="ele">        
          {this.state.title && <Details title={this.state.title} time={this.state.time} source={this.state.source} content={this.state.content}></Details>}
      </div>
    </Spin>
  }
}
export default Contracts
