import React, { Component } from 'react';
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Route } from 'react-router-dom';
import Finance from '../../components/Branch/Finance';
import Register from '../../components/Branch/Register';
import Television from '../../components/Branch/Television';
import Development from '../../components/Branch/Development';
import International from '../../components/Branch/International';
import Intellectual from '../../components/Branch/Intellectual';

// import Reserve from '../../components/Branch/Reserve';
import bannerImg from '../../assets/images/jcdj.jpg'
import Details from '../../components/Details'
import './index.css'

class Branch extends Component {
  state={
      sideTitle: "分支机构",
      enSideTitle: 'Branch',
      sideList: [
        {
          title: '金融仲裁院',
          enTitle: 'Financial Arbitration Court',          
          link: '/Branch/finance'
        },
        {
          title: '影视文化仲裁院',
          enTitle: 'Entertainment and culture arbitration court',          
          link: '/Branch/television'
        },
        {
          title: '建设工程仲裁院',
          enTitle: 'Construction Arbitration Institute',
          link: '/Branch/development'
        },
        {
          title: '中国长沙国际仲裁院',
          enTitle: 'Changsha international arbitration court',
          link: '/Branch/international'
        },
        {
          title: '知识产权仲裁院',
          enTitle: 'Intellectual Property Arbitration Court',
          link: '/Branch/intellectual'
        },
      ]
    }

  componentDidMount(){
    window.localStorage.setItem('url', '/Branch')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Branch/finance" render={() => (
        <Finance isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Branch/finance" component={Finance}></Route> 
    
  }
  render() {
    return <div id="branch">
      <Header currentPage="/Branch"></Header>
      <Banner bannerTitle="分支机构" bannerEnTitle="Branch" type="8"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/Branch" component={Finance} ></Route>
          {/* <Route path="/Branch/finance" component={Finance} ></Route> */}
          {this.renderScrollComponent()}
          <Route path="/Branch/register" component={Register} ></Route>
          <Route path="/Branch/television" component={Television} ></Route>
          <Route path="/Branch/international" component={International} ></Route>

          <Route path="/Branch/intellectual" component={Intellectual} ></Route>

          <Route path="/Branch/development" component={Development} ></Route>
          <Route path="/Branch/Details" component={Details} ></Route>
          {/* <Route exact path="/Branch/reserve" component={Reserve} ></Route> */}
        </div>
      </div>   
      <Footer></Footer>     
    </div>
  }
}
export default Branch