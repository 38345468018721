/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-04 20:02:14
 * @LastEditTime: 2019-08-27 10:10:17
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './index.css'
import { Button, Spin, message, Icon } from 'antd';
import store from '../../redux/store'
class Details extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      time: '',
      content: '',
      source: '',
      image: '',
      loading: false,
    }
  }
  componentDidMount() {
    var _hmt = _hmt || [];
    _hmt.push(['_trackPageview', '/Mien/Details']);
    store.subscribe(() => {
      this.setState({
        downList: store.getState().downList
      })
    })

    if (this.props && this.props.title) {
      this.setState({
        title: this.props ? this.props.title : '暂无数据',
        time: this.props ? this.props.time : '暂无数据',
        content: this.props ? this.props.content : '暂无数据',
        source: this.props ? this.props.source : '暂无数据',
        loading: false
      })
    } else {
      let url = window.location.href;
      let query = url.substring(url.lastIndexOf('/') + 1).match(/[0-9]*$/)[0]
      // let id = this.props.location && this.props.location.state.id;
      let id = ''
      if(this.props.id){
      if (this.props.id) {
        id = this.props.id
      } else {
        // id = query
      }
    }else{
      if (this.props.location.state && this.props.location.state.id) {
        id = this.props.location.state.id
      } else {
        id = query;
      }
    }
      if (id !== undefined) {
        this.setState({
          loading: true
        })
        fetch('/zhongcai/information/' + id, {
          method: "GET",
          headers: new Headers({
            'requestType': 'official_website'
          })
        }).then((res) => {
          if (res.status >= 500) {
            message.error('服务器出错，请稍后重试', 3)
            return false
          }
          return res.json();
        }).then(res => {
          if (!res) {
            return false;
          }
          let data = res.data.information;
          if (data.shows === 1) {
            message.error("该数据已被管理员修改为不展示！", 5);
            this.setState({
              loading: false
            })
            return false
          }
          this.setState({
            title: data.title,
            time: data.createat,
            content: data.content,
            source: data.source,
            image: data.image ? data.image : '',
            loading: false
          })
        })
      }
    }

  }
  renderDownList() {
    let list = this.state.downList
    return list.map((item, index) => {
      return <p key={index}>
        <a href={'http://' + item.address} download>{item.name}</a>
      </p>
    })
  }
  back() {
    if (this.props.title || this.props.location.state) {
      this.props.history.goBack()
    } else {
      window.close();
    }
  }

  render() {
    const { title, time, content, source, } = this.state;
  
    const contractBox =  document.getElementById('contract-container')?document.getElementById('contract-container').parentElement:  document.getElementById('contract-container');
    
    if (contractBox) { 
      document.querySelector('#contract-container').scrollIntoView(true);
    }
    
    return <div id="details">
      <Spin spinning={this.state.loading} size="large">
        <h3 className="title">{title}</h3>
        <p className='publish-time'>发布时间：<span>{time}</span>来源：<span>{source}</span></p>
        <div className="line-dashed"></div>
        <div className="img">
          <img src={this.state.image} />
        </div>
        <div className="details-content" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="back-btn"><Button type="primary" onClick={this.back.bind(this)}>返回</Button></div>
      </Spin>
    </div>

  }
}
export default withRouter(Details)