import React, { Component } from 'react';
import { Pagination } from 'antd';
import MenuItem from '../../MenuItem'
import './index.css';
import { Spin, message } from 'antd';
import store from '../../../redux/store'
class Train extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      pageSize: 5,
      total: 5,
      pageNum: 1
    }
  }
  getData(pageSize, page) {
    fetch('/zhongcai/information/searchShow/?pid=3&sid=25&pageSize=' + pageSize + '&pageNum=' + page,{
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      let data = res.data.pageInfo
      if(data.list.length !== 0) {
        this.setState({
          list: data.list,
          loading: false,
          total: data.total
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '仲裁员培训'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    window.scrollTo(0, h)
  }
  renderItem() {
    let list = this.state.list;
    return list.map((item, index) => {
      return <MenuItem key={index} content={item.title} time={item.createat} urlId={item.id} dir="/Arbitrator"></MenuItem>
    })
  }
  onChange(page, pageSize) {
    this.getData(pageSize, page)
  }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id="train" ref="ele">
        <div className="allSameBox">
          {this.renderItem()}
        </div>
        <div className='pagination' id="common">
          <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
        </div>
      </div>
    </Spin>
  }
}
export default Train


