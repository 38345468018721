import React, { Component } from 'react';
import Banner from '../../components/Banner/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SideBar from '../../components/SideBar/index';
import { Route } from 'react-router-dom';
import './index.css'

import Standard from '../../components/Arbitrator/Standard';
import Service from '../../components/Arbitrator/Service';
import List from '../../components/Arbitrator/List';
import Appearance from '../../components/Arbitrator/Appearance';
import Train from '../../components/Arbitrator/Train';
import Application from '../../components/Arbitrator/Application';
import Details from '../../components/Details';
import Registration from '../../components/Arbitrator/Registration'
import Handle from '../../components/Arbitrator/Handle'
// import NewsDetails from '../NewsDetails/index';
import bannerImg from '../../assets/images/zcy.jpg'
class Arbitrator extends Component {
  state={
      sideTitle: "仲裁员",
      enSideTitle: 'Arbitrator',
      sideList: [
        {
          title: '仲裁员管理规范',
          enTitle: 'Arbitrator Management Code',          
          link: '/Arbitrator/standard'
        },
        {
          title: '服务仲裁员',
          enTitle: 'Service arbitrator',
          link: '/Arbitrator/service'
        },
        {
          title: '仲裁员名册',
          enTitle: 'List of Arbitrators',
          link: '/Arbitrator/list'
        },
        {
          title: '仲裁员风采',
          enTitle: 'Arbitrator style',
          link: '/Arbitrator/appearance'
        },
        {
          title: '仲裁员培训',
          enTitle: 'Arbitrator training',
          link: '/Arbitrator/train'
        },
        {
          title: '申请仲裁员',
          enTitle: 'Application for arbitrator',
          link: '/Arbitrator/application'
        },
        // {
        //   title: '仲裁员年度注册',
        //   enTitle: 'Annual registration of Arbitrators',
        //   link: '/Arbitrator/registration'
        // },
        {
          title: '仲裁员在线办案',
          enTitle: 'Arbitrators handle cases Online',
          link: '/Arbitrator/handle'
        }
      ]
    }

  componentDidMount(){
    window.localStorage.setItem('url', '/Arbitrator')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Arbitrator/standard" render={() => (
        <Standard isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Arbitrator/standard" component={Standard}></Route> 
    
  }
  render() {
    return <div id="arbitrator">
      <Header currentPage="/Arbitrator"></Header>
      <Banner bannerTitle="仲裁员" bannerEnTitle="Arbitrator" type="4"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/Arbitrator" component={Standard}></Route>
          {/* <Route exact path="/Arbitrator/standard" component={Standard}></Route> */}
          {this.renderScrollComponent()}
          <Route exact path="/Arbitrator/service" component={Service}></Route>
          <Route exact path="/Arbitrator/list" component={List}></Route>
          <Route exact path="/Arbitrator/appearance" component={Appearance}></Route>
          <Route exact path="/Arbitrator/train" component={Train}></Route>
          <Route exact path="/Arbitrator/application" component={Application}></Route>
          <Route exact path="/Arbitrator/registration" component={Registration}></Route>
          <Route exact path="/Arbitrator/handle" component={Handle}></Route>
          <Route path="/Arbitrator/Details" component={Details}></Route>
          
        </div> 
      </div> 
      <Footer></Footer>      
    </div>
  }
}
export default Arbitrator