import React, { Component } from 'react';
import { Input, Select , Table ,Button, Icon, message } from 'antd';
import './index.css'
import store from '../../../redux/store'
const InputGroup = Input.Group;
const Option = Select.Option;
const Search = Input.Search;

class Library extends Component {
  state = {
    dataSource: [],
    datatable:[],
    pageSize: 10,
    pageNum: 1,
    total: 10,
    findType: 1,
    findValue: '',
    nowkey:'1'
  }
  componentDidMount() {
    this.showAll(this.state.pageSize, this.state.pageNum)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '仲裁鉴定机构库'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
  }

  onSearch(value){
    if(!value) {
      message.error('搜索内容不能为空！', 3)
      return false;
    }
    this.setState({
      findValue: value
    })
    this.showAll(10, 1, this.state.findType, value)
    //todo  根据 姓名专业居住地查找
  }

  //显示全部
  showAll(pageSize, pageNum, type = '', str = ''){
    let url = '/zhongcai/body/?pageSize=' + pageSize + '&pageNum=' + pageNum; 
    if(type !== '' && str !== '') {
      url = '/zhongcai/body/?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + encodeURIComponent(type) + '&findStr=' + encodeURIComponent(str);
    }
    fetch(url, {
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      let arr = []      
      let data = res.data.pageInfo.list
      data.forEach((item,index) => {
        console.log(pageNum)
        let obj = {
          key:item.id,
          name:item.name,
          category: item.className,
          website: item.websiteUrl,
          index: pageNum - 1 > 0 ? ((pageNum - 1) * pageSize + index + 1) : index + 1
        }
        arr[index] = obj
      });
      // console.log(arr)
      this.setState({
        datatable:arr,
        total: res.data.pageInfo.total
      }, () => {
        // console.log(this.state.total);
      })
    })
  }

  
  handleChange(e){
    this.setState({
      findType: e
    })
  }
  pageChange(obj) {
    this.showAll(obj.pageSize, obj.current, this.state.findType, this.state.findValue)
  }
  render() {

    const columns = [{
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    }, {
      title: '机构名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '机构类别',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: '机构官网',
      dataIndex: 'website',
      key: 'website',
      render: item => {
        if(item.indexOf('http') === -1) {
          item = 'http://' + item
        }
        return <a href={item} target="_blank" rel="noopener noreferrer" >{item}</a>
      }
    }];
    const data = this.state.datatable 
    return <div id="library" ref="ele">     
      <InputGroup 
      compact
      style={{marginBottom:"31px"}}
      >
        <div className='label'>仲裁鉴定机构库:</div>
        <Select defaultValue="名称" onChange={this.handleChange.bind(this)} className="selectBox" size='large' suffixIcon={<Icon type="caret-down" />}>
          <Option value="1" key="1">名称</Option>
          <Option value="2" key="2">类别</Option>
        </Select>
        <Search
            placeholder="search"
            onSearch={this.onSearch.bind(this)}
            style={{ width: 330 }}
            size='large'
          />
        <Button className="buttonBox" onClick={this.showAll.bind(this, 10, 1)}>显示全部</Button>
      </InputGroup>    
      <Table locale={{emptyText: '暂无数据' }} columns={columns} dataSource={data} rowClassName="rowrua" bordered pagination={{total: this.state.total}} onChange={this.pageChange.bind(this)}/>
    </div>    
  }
}
export default Library