import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";

// import Header from './components/Header'
// import Footer from './components/Footer'
import Home from './pages/Home/index'
import Mien from './pages/Mien/index'
import Guide from './pages/Guide/index'
import FastBooting from './pages/FastBooting/index'
import Arbitrator from './pages/Arbitrator/index'
import CheckUp from './pages/CheckUp/index'
import Information from './pages/Information/index'
import Branch from './pages/Branch/index'
import Basic from './pages/Basic/index'
import SearchResult from './pages/SearchResult/index'
import SearchDetail from './pages/SearchDetail/index'

import './App.css';
class App extends Component {
  componentDidMount() {
  }
  componentDidUpdate() {
    
  }
  render() {
    return (
      <Router>
        <div className="App">
          {/* <Header></Header> */}
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/SearchResult" component={SearchResult} />
          <Route path="/SearchDetail" component={SearchDetail} />
          <Route path="/Mien" component={Mien} />
          <Route path="/Guide" component={Guide} />
          <Route path="/FastBooting" component={FastBooting} />
          <Route path="/Arbitrator" component={Arbitrator} />
          <Route path="/CheckUp" component={CheckUp} />
          <Route path="/Information" component={Information} />
          <Route path="/Branch" component={Branch} />
          <Route path="/Basic" component={Basic} />
          {/* <Footer></Footer> */}
        </div>
      </Router>
    );
  }
}

export default App;
