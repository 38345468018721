import React, { Component } from 'react';
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Route } from 'react-router-dom';
import Introduction from '../../components/Basic/Introduction';
import Building from '../../components/Basic/Building';
import Details from '../../components/Details'
import './index.css'
import bannerImg from '../../assets/images/dj.png'
// import NewsDetails from '../NewsDetails/index';
class Basic extends Component {
  state={
      sideTitle: "基层党建",
      enSideTitle: 'Party building',
      sideList: [
        {
          title: '秘书处党支部介绍',
          enTitle: 'Introduction of Party Branch of Secretariat',          
          link: '/Basic/introduction'
        },
        {
          title: '基层党建工作动态',
          enTitle: 'The dynamics of Party Building',
          link: '/Basic/building'
        }
      ]
    }

  componentDidMount(){
    window.localStorage.setItem('url', '/Basic')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Basic/introduction" render={() => (
        <Introduction isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Basic/introduction" component={Introduction}></Route> 
    
  }
  render() {
    return <div id="basic">
      <Header currentPage="/Basic"></Header>
      <Banner bannerTitle="基层党建" bannerEnTitle="Party building" type="9"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/Basic" component={Introduction} ></Route>
          {/* <Route exact path="/Basic/introduction" component={Introduction} ></Route> */}
          {this.renderScrollComponent()}
          <Route exact path="/Basic/building" component={Building} ></Route>
          <Route path="/Basic/Details" component={Details} ></Route>
        </div> 
      </div> 
      <Footer></Footer>      
    </div>
  }
}
export default Basic