import React, {Component} from 'react';
import { Input, Button, Spin, message,modal } from 'antd'
import './index.css'
import store from '../../../redux/store'
const { TextArea } = Input;
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      concat: '',
      content: '',
      loading: false,
      errInfo: '',
      tipShow: false,
      wordCount: 0,
      imgCode: '',
      imgCodeFlag: false,
      btnLoading: false     
    }
  }
  componentDidMount() {
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '反馈投诉'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
    this.getImgCode()
  }
  // 获取图形验证码
  getImgCode() {
    this.setState({
      btnLoading: true
    })
    fetch('/zhongcai/image/getVerify',{//通过pid以及sid获取到当前类目的id  
      method:"POST",
      headers: new Headers({
        'Content-Type': 'application/json',
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res >= 500) {
        message.error('服务器出错，获取验证码失败', 3)
        this.setState({
          btnLoading: false
        })
      }
      return res.blob()
    }).then(res => {
      this.setState({
        btnLoading: false
      })
      let fr = new FileReader();
      fr.onload =  (e) => { 
        this.setState({
          imgCode: e.target.result
        })
      }
      fr.readAsDataURL(res);
    })
    .catch(err => {
      message.error('请求异常，获取验证码失败', 3)
      this.setState({
        btnLoading: false
      })
    }) 
  }

  TextAreaChange() {
    const value =  this.refs['content'].state.value;
    if(value){
      const length = value.length;
      this.setState({
        wordCount: length
      });
      // 改：设置了maxlength  ,这个可以忽略
      // if(length > 1000) { 
      //   this.setState({
      //     content: value.substring(0, 1000)
      //   })
      //   return 
      // }
      
    }
  }
  //换一组图形验证码
  changeCode() {
    this.getImgCode()
  }
  //验证验证码是否正确
  checkCode() {
    if(!this.refs['code-input'].state.value) {
      return
    }
    let codeValue = this.refs['code-input'].state.value
    fetch(`/zhongcai/image/checkVerify?ver=${codeValue}`, {
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then(res => {
      return res.json()
    }).then(res => {
      const { errno }=res;
      if(errno === -1) {
        message.error('验证码输入错误,请重试', 3)
        this.setState({
          imgCodeFlag: false
        })
        return 
      }
      this.setState({
        imgCodeFlag: true
      })
    })
  }
  //提交信息
  handleSubmit() {
    if(!this.refs['name'].state.value) {
      message.error('请输入姓名', 3)
      return
    }
    if(!this.refs['concat'].state.value) {
      message.error('请输入联系方式', 3)
      return
    }
    if(!this.refs['content'].state.value) {
      message.error('请输入反馈内容', 3)
      return
    }   
    if(!this.refs['code-input'].state.value) {
      message.error('请输入验证码', 3)
      return
    }
    if(!this.state.imgCodeFlag) {
      message.error('验证码输入错误，请重新输入', 3)
      return
    }
    this.setState({
      loading: true,
      tipShow: false
    })
    let json = {
      contactInformation: this.refs['concat'].state.value,
      feedbackContent: this.refs['content'].state.value,
      name: this.refs['name'].state.value
    }
    fetch('/zhongcai/feedback/',{//通过pid以及sid获取到当前类目的id  
      method:"POST",
      body: JSON.stringify(json),
      headers: new Headers({
        'Content-Type': 'application/json',
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        this.setState({
          loading: false
        })
        return
      }
      this.setState({
        loading: false
      })
      modal.success( {
        content: '您的反馈信息已成功提交，我们将会尽快处理',
        okText: '确定'
      })
    })
  }
  render() {
    return <Spin spinning={this.state.loading} delay={500}>
      <div id="complaints" ref="ele">     
        <div>
          <span>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名<i></i></span>
          <Input placeholder="请填写您的姓名" ref="name"/>
        </div>
        <div>
          <span>联系方式<i></i></span>
          <Input placeholder="请填写您的联系方式" ref="concat"/>
        </div>
        <div>
          <span>反馈内容<i></i></span>
          <div className="text-area-content">
            <TextArea placeholder="请输入您的反馈内容" ref="content" maxLength='1000'
              onChange={this.TextAreaChange.bind(this)}
              onKeyDown={this.TextAreaChange.bind(this)}
              onKeyUp={this.TextAreaChange.bind(this)}
            />
            <p>{this.state.wordCount} / 1000</p>
          </div>
        </div>
        <div className="code-box">
          <span>验&nbsp;&nbsp;证&nbsp;&nbsp;码<i></i></span>
          <div>
            <Input ref="code-input" onBlur={this.checkCode.bind(this)}/>
            <img src={this.state.imgCode} />
            <Button type="primary" onClick={this.changeCode.bind(this)} loading={this.state.btnLoading}>换一组</Button>
          </div>
        </div>
        <div>
          <span></span>
          <Button type="primary" onClick={this.handleSubmit.bind(this)}>提交</Button>
        </div>      
      </div>
    </Spin>   
  }
}
export default App