import React, { Component } from 'react';
import { Pagination, Tabs } from 'antd';
import MenuItem from '../../MenuItem'
import './index.css';
import '../../../assets/common.css'
import { Spin, message } from 'antd';
import store from '../../../redux/store'
import Register from '../Register/index'
const TabPane = Tabs.TabPane;
class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      pageSize: 5,
      total: 5,
      pageNum: 1,
      sid: 43
    }
  }
  getData(pageSize, page, sid) {
    this.setState({
      loading: true
    })
    fetch('/zhongcai/information/searchShow/?pid=7&sid=' + sid + '&pageSize=' + pageSize + '&pageNum=' + page,{
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      let data = res.data.pageInfo
      if(data.list.length !== 0) {
        this.setState({
          list: data.list,
          loading: false,
          total: data.total,
          pageNum: data.pageNum
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum, this.state.sid)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '金融仲裁院'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    if(this.props.isScroll === false) {

    } else {
      setTimeout(() => {
        document.documentElement.scrollTop = h;
      }, 0)
    }
  }
  renderItem() {
    let list = this.state.list;
    return list.map((item, index) => {
      return <MenuItem key={index} content={item.title} time={item.createat} urlId={item.id} dir="/Branch"></MenuItem>
    })
  }
  onChange(page, pageSize) {
    this.getData(pageSize, page, this.state.sid);
  }
  callback(key) {
    if(parseInt(key) === 46) {
      return false
    }
    this.setState({
      sid: key,
      list: []
    })
    this.getData(this.state.pageSize, 1, key)
    
  }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id="finance" ref="ele">
      <Tabs defaultActiveKey="1" onChange={this.callback.bind(this)}>
          <TabPane tab="金融仲裁院概况" key="43">
            <div className="allSameBox">
              {this.renderItem()}
            </div>
            <div className='pagination' id="common">
              <Pagination  current={this.state.pageNum} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
            </div>
          </TabPane>
          <TabPane tab="金融仲裁规则" key="44">
            <div className="allSameBox">
              {this.renderItem()}
            </div>
            <div className='pagination' id="common">
              <Pagination current={this.state.pageNum} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
            </div>
          </TabPane>
          <TabPane tab="金融仲裁员推荐名册" key="46">
            <Register />
          </TabPane>
          <TabPane tab="办案流程及指南" key="45">
            <div className="allSameBox">
              {this.renderItem()}
            </div>
            <div className='pagination' id="common">
              <Pagination current={this.state.pageNum} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  }
}
export default Finance