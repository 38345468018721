import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import './index.css'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index' ;
import NewsList from '../../components/Mien/NewsList';
import AboutUs from '../../components/Mien/AboutUs';
import Organize from '../../components/Mien/Organize';
import Member from '../../components/Mien/Member';
import Development from '../../components/Mien/Development';
import Special from '../../components/Mien/Special';
import Culture from '../../components/Mien/Culture';
import Details from '../../components/Details';
// import Tables from '../../components/Mien/Tables';
import Notice from '../../components/Mien/Notice';
// import NewsDetails from '../NewsDetails/index';
import bannerImg from '../../assets/images/banner.png'
class Mein extends Component {
  state={
    sideTitle: "长仲风采",
    enSideTitle: 'Elegant demeanour',
    sideList: [
      {
        title: '关于我们',
        enTitle: 'About Us',          
        link: '/Mien/aboutUs'
      },
      {
        title: '发展历程',
        enTitle: 'The development course',
        link: '/Mien/development'
      },
      {
        title: '组织机构',
        enTitle: 'The organization',
        link: '/Mien/organize'
      },
      {
        title: '委员会成员',
        enTitle: 'Committee member',
        link: '/Mien/member'
      },
      {
        title: '专门委员会',
        enTitle: 'Special committee',
        link: '/Mien/special'
      },
      // {
      //   title: '首席专家库',
      //   enTitle: 'Chief Expert Library',
      //   link: '/Mien/tables'
      // },     
      {
        title: '机构文化',
        enTitle: 'Organization Culture',
        link: '/Mien/culture'
      },
      {
        title: '仲裁资讯',
        enTitle: 'Arbitration information',
        link: '/Mien/newslist'
      },
      {
        title: '长仲公告',
        enTitle: 'Announcement management',
        link: '/Mien/notice'
      }
    ]
  }

  componentDidMount(){

  }

  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Mien/aboutUs" render={() => (
        <AboutUs isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Mien/aboutUs" component={AboutUs}></Route> 
    
  }

  render() {
    return <div id="mein">
      <Header currentPage="/Mien"></Header>
      <Banner bannerTitle="长仲风采" bannerEnTitle="Elegant demeanour" type="2"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          {this.renderScrollComponent()}
          <Route path="/Mien/organize" component={Organize}></Route>
          <Route path="/Mien/member" component={Member}></Route>
          <Route path="/Mien/development" component={Development}></Route>
          <Route path="/Mien/special" component={Special}></Route>
          <Route path="/Mien/culture" component={Culture}></Route>
          <Route path="/Mien/newslist" component={NewsList}></Route>
          <Route path="/Mien/details" component={Details}></Route>
          <Route path="/Mien/notice" component={Notice}></Route>
          {/* <Route path="/Mien/tables" component={Tables}></Route> */}
        </div> 
      </div>   
      <Footer></Footer>     
    </div>
  }
}
export default Mein