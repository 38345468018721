import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Input, message } from 'antd'
import './index.css';
import DropMenu from '../DropMenu';
import store from '../../redux/store'
import imgURL from '../../assets/images/logo.png';
const menuList = [
  // 长仲风采
  [
    {
      title: '关于我们',
      link: '/Mien/aboutUs'
    },
    {
      title: '发展历程',
      link: '/Mien/development'
    },
    {
      title: '组织机构',
      link: '/Mien/organize'
    },
    {
      title: '委员会成员',
      link: '/Mien/member'
    },
    {
      title: '专门委员会',
      link: '/Mien/special'
    },
    // {
    //   title: '首席专家库',
    //   link: '/Mien/tables'
    // },   
    {
      title: '机构文化',
      link: '/Mien/culture'
    },
    {
      title: '仲裁资讯',
      link: '/Mien/newslist'
    },
    {
      title: '长仲公告',
      link: '/Mien/notice'
    }
  ],
  // 仲裁指南
  [
    {
      title: '当事人仲裁指南',
      link: '/Guide/guideParties'
    },  
    {
      title: '仲裁规则',
      link: '/Guide/rules'
    },
    {
      title: '仲裁流程',
      link: '/Guide/process'
    },
    {
      title: '仲裁收费',
      link: '/Guide/charge'
    },
    {
      title: '文书下载',
      link: '/Guide/downFile'
    },
    {
      title: '合同下载',
      link: '/Guide/contracts'
    },
    {
      title: '常见问题',
      link: '/Guide/question'
    }
  ],
  //仲裁员
  [
    {
      title: '仲裁员管理规范',
      link: '/Arbitrator/standard'
    },  
    {
      title: '服务仲裁员',
      link: '/Arbitrator/service'
    },
    {
      title: '仲裁员名册',
      link: '/Arbitrator/list'
    },
    {
      title: '仲裁员风采',
      link: '/Arbitrator/appearance'
    },
    {
      title: '仲裁员培训',
      link: '/Arbitrator/train'
    },
    {
      title: '申请仲裁员',
      link: '/Arbitrator/application'
    },
    // {
    //   title: '仲裁员年度注册',
    //   link: '/Arbitrator/registration'
    // },
    {
      title: '仲裁员在线办案',
      link: '/Arbitrator/handle'
    }
  ],
  //在线服务
  [
    {
      title: '申请仲裁指南',
      link: '/FastBooting/Guide'
    },
    // {
    //   title: '申请仲裁',
    //   link: '',
    //   href: 'http://www.baidu.com'
    // },      
    {
      title: '申请专家',
      link: '/FastBooting/specialist',
      href: ''
    },
    {
      title: '费用快算',
      link: '/FastBooting/ChargeFastCalculate'
    },
    {
      title: '反馈投诉',
      link: '/FastBooting/Complaints'
    },
    {
      title: '加入我们',
      link: '/FastBooting/JoinUs'
    }
  ],
  //仲裁鉴定
  [
    {
      title: '鉴定工作管理制度',
      link: '/CheckUp/management'
    },  
    {
      title: '鉴定工作指引',
      link: '/CheckUp/guide'
    },
    {
      title: '鉴定机构库',
      link: '/CheckUp/library'
    },
    {
      title: '鉴定机构申报',
      link: '/CheckUp/application'
    },
    {
      title: '鉴定机构年审',
      link: '/CheckUp/review'
    }
  ],
  //仲裁资料
  [
    {
      title: '法律法规',
      link: '/Information/laws'
    },  
    {
      title: '案例专栏',
      link: '/Information/case'
    },
    {
      title: '仲裁研究',
      link: '/Information/research'
    },
    {
      title: '电子期刊',
      link: '/Information/changsha'
    },
    {
      title: '历史资料',
      link: '/Information/historical'
    }
  ],
   //分支机构
  [
    {
      title: '金融仲裁院',
      link: '/Branch/finance'
    },  
    {
      title: '影视文化仲裁院',
      link: '/Branch/television'
    }, 
    {
      title: '建设工程仲裁院',
      link: '/Branch/development'
    }, 
    {
      title: '中国长沙国际仲裁院',
      link: '/Branch/international'
    },
    {
      title: '知识产权仲裁院',
      link: '/Branch/intellectual'
    }
  ],
   //基层党建
  [
    {
      title: '秘书处党支部介绍',
      link: '/Basic/introduction'
    },  
    {
      title: '基层党建工作动态',
      link: '/Basic/building'
    }
  ]
]
class Header extends Component {
  state = {
    currentPageIndex: 0,
    url: '',
    searchText: store.getState().searchText
  }
  componentDidMount() {
    this.setState({
      url: this.props.currentPage
    })
  }
  oddEvent(e) {
    console.log(e)
  }
  search() {
    let value = this.refs['search-input'].input.value
    if(!value.trim()) {
      message.error('内容不能为空！', 3)
      return false
    }
    store.dispatch({
      type: "CHANGE_SEARCH_TEXT",
      payload: value
    })
    let hrefArr = window.location.href.split('/')
    if(hrefArr[hrefArr.length - 1].indexOf('SearchResult') !== -1) {
      this.props.searchFn()
    }
    this.props.history.push('/SearchResult')
  }
  render() {
    return <div id="header">
    {/* 页面最顶部 */}
      <div className="header-top">
        <div className="header-top-content">
          <p className="header-welcome">欢迎访问长沙仲裁委员会</p>
          
          <p className="header-right">
            <span><a href="http://zc.cscac.net" target="_blank" rel="noopener noreferrer">当事人登录</a></span>
            <span><a href="http://oa.cscac.net" target="_blank" rel="noopener noreferrer">仲裁委登录</a></span>
          </p>
          {/* <Input ref="search-input" addonAfter={<NavLink to={ {pathname:'/ResultDetail', query:{content: this.refs['search-input'].input.value}} }>搜索</NavLink>}/> */}
          <Input ref="search-input" addonAfter={<span onClick={this.search.bind(this)} >搜索</span>} defaultValue={this.state.searchText}/>
        </div>
      </div>
    {/* 页面头部导航 */}
      <div className="header-nav">
        <div className="header-nav-box">
          <div className="header-logo">
            <NavLink to="/"><img src={imgURL} alt=""/></NavLink>
          </div>
          <div className="header-nav-list">
          <div className={this.state.url === '/' ? 'selected' : ''}><NavLink to="/" activeClassName="selected" >首页</NavLink></div>
          <div className={this.state.url === '/Mien' ? 'selected' : ''}>
            <NavLink to={{pathname:'/Mien/aboutUs', query:{'isScroll': false} }} activeClassName="selected">长仲风采</NavLink>
            <DropMenu list={menuList[0]}></DropMenu>
          </div>
          <div className={this.state.url === '/Guide' ? 'selected' : ''}>
            <NavLink to={{pathname:'/Guide/guideParties', query:{'isScroll': false} }} activeClassName="selected">仲裁指南</NavLink>
            <DropMenu list={menuList[1]}></DropMenu>
          </div>
          <div className={this.state.url === '/Arbitrator' ? 'selected' : ''}>
            <NavLink to={{pathname:'/Arbitrator/standard', query:{'isScroll': false} }} activeClassName="selected">仲裁员</NavLink>
            <DropMenu list={menuList[2]}></DropMenu>
          </div>
          <div className={this.state.url === '/FastBooting' ? 'selected' : ''}> 
            <NavLink to={{pathname:'/FastBooting/Guide', query:{'isScroll': false} }} activeClassName="selected">在线服务</NavLink>
            <DropMenu list={menuList[3]}></DropMenu>
          </div>
          <div> 
            <a href="https://blockchain.cscac.net/first" target="_blank" rel="noopener noreferrer">区块链证据平台</a>
          </div>
          <div className={this.state.url === '/CheckUp' ? 'selected' : ''}>
            <NavLink to={{pathname:'/CheckUp/management', query:{'isScroll': false} }} activeClassName="selected">仲裁鉴定</NavLink>
            <DropMenu list={menuList[4]}></DropMenu>
          </div>
          <div className={this.state.url === '/Information' ? 'selected' : ''}>
            <NavLink activeClassName="selected" to={{pathname:'/Information/laws', query:{'isScroll': false} }}>仲裁资料</NavLink>
            <DropMenu list={menuList[5]}></DropMenu>
          </div>
          <div className={this.state.url === '/Branch' ? 'selected' : ''}>
            <NavLink to={{pathname:'/Branch/finance', query:{'isScroll': false} }} activeClassName="selected">分支机构</NavLink>
            <DropMenu list={menuList[6]}></DropMenu>
          </div>
          <div className={this.state.url === '/Basic' ? 'selected' : ''}>
            <NavLink to={{pathname:'/Basic/introduction', query:{'isScroll': false} }} activeClassName="selected">基层党建</NavLink>
            <DropMenu list={menuList[7]}></DropMenu>
          </div>
        </div>
        </div>
      </div>
    </div>
  }
}
export default withRouter(Header)