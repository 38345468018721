import React, { Component } from 'react'
import { Button, Spin, message, Icon, Input, Progress, } from 'antd';
import './index.css';

export default class JoinUsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            time: '',
            content: '',
            num: '',
            loading: false,
            closeModal: false,
            uploadProgress: 0,
            closeModal: false,//提交简历弹窗
            file: '',//上传的文件
            introduce: '',//选择上传文件名称
            isChooseFile: false,//是否选择了上传文件
            fileAddress: '',//文件上传成功后的地址,
            success: false,
            modalLoading: false,
            imgCode: '',
            imgCodeFlag: false,
            btnLoading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        if (this.props.location.state) {
            const id = this.props.location.state.id;
            fetch('/zhongcai/offer/?pageSize=10', {
                method: "GET",
                headers: new Headers({
                    'requestType': 'official_website'
                })
            }).then((res) => {
                return res.json();
            }).then(res => {
                this.setState({
                    loading: false
                })
                this.getImgCode();
                let arr = res.data.pageInfo.list || "";
                arr.forEach((item, index) => {
                    if (id === item.id) {
                        this.setState({
                            time: item.createat,
                            num: item.number,
                            title: item.post,
                            content: item.description
                        })
                    }
                });
            }).catch((res) => {
                console.log(res);
            });
        } else {
            this.setState({ loading: false })
        }
    }
    back() {
        window.history.back()
    }

    showModal() {
        this.setState({
            closeModal: true
        })

    }

    //获取图形验证码
    getImgCode() {
        this.setState({
            btnLoading: true
        })
        fetch('/zhongcai/image/getVerify', {//通过pid以及sid获取到当前类目的id  
            method: "POST",
            headers: new Headers({
                'Content-Type': 'application/json',
                'requestType': 'official_website'
            })
        }).then((res) => {
            if (res >= 500) {
                message.error('服务器出错，获取验证码失败', 3)
                this.setState({
                    btnLoading: false
                })
            }
            return res.blob()
        }).then(res => {
            // console.log(res.toString())
            this.setState({
                btnLoading: false
            })
            let fr = new FileReader();
            fr.onload = (e) => {
                this.setState({
                    imgCode: e.target.result
                })
            }
            fr.readAsDataURL(res);
        })
            .catch(err => {
                message.error('请求异常，获取验证码失败', 3)
                this.setState({
                    btnLoading: false
                })
            })
    }

    fetchProgress(url, opts = {}, onProgress) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open(opts.method || 'get', url);
            for (var key in opts.headers || {}) {
                xhr.setRequestHeader(key, opts.headers[key]);
            }

            xhr.onload = e => resolve(e.target.responseText)
            xhr.onerror = reject;
            if (xhr.upload && onProgress) {
                xhr.upload.onprogress = onProgress; //上传
            }
            if (xhr.onerror) {
                xhr.onerror = (e) => {
                    message.error('上传失败:' + e, 4)
                }
            }
            if ('onprogerss' in xhr && onProgress) {
                xhr.onprogress = onProgress; //下载
            }
            xhr.send(opts.body)
        })
    }

    fileChange(e) {
        let file = this.refs['fileInput'].files[0];
        let fd = new FormData();
        fd.append('file', file)
        // let input = document.querySelector('#file')
        let fileSize = file.size
        console.log(fileSize)
        if (fileSize > 1024 * 1024 * 5) {
            e.target.value = ''
            message.error("文件过大，请选择不大于5M的简历附件", 3)
            return false
        }
        this.setState({
            file: fd,
            introduce: file.name,
            isChooseFile: true
        })
        // if (e.target.files.length !== 0) {
        //     var file = e.target.files[0];
        //     var formData = new FormData();
        //     formData.append('file', file)
        // }
    }

    //关闭弹窗
    closeModal() {
        this.setState({
            closeModal: false,
            uploadProgress: 0,
            success: false,
            fileAddress: '',
            isChooseFile: false
        })
        this.cancelChoose();
        this.refs['name'].input.value = '';
        this.refs['concat'].input.value = '';
    }

    uploadFile() {
        if (!this.state.file) {
            message.error('请选择简历附件再上传！', 3)
            return
        }
        this.fetchProgress('/zhongcai/oss/uploadFile', {
            method: 'POST',
            headers: new Headers({
                'requestType': 'official_website'
            }),
            body: this.state.file,
        }, (e) => {
            this.setState({
                uploadProgress: parseInt(((e.loaded / e.total) * 90).toFixed(0))
            })
        }).then(res => {
            this.setState({
                uploadProgress: 100
            })
            console.log(res)
            this.setState({
                fileAddress: JSON.parse(res).data.fileUrl
            })
        })
    }
    // 删除已经上传的文件
    delUpload() {
        this.setState({
            uploadProgress: 0
        })
        this.cancelChoose()
    }
    //取消选择的上传文件
    cancelChoose() {
        this.setState({
            isChooseFile: false,
            file: '',
            introduce: '',
            fileAddress: ''
        })
    }

    //换一组图形验证码
    changeCode() {
        this.getImgCode()
    }
    //验证验证码是否正确
    checkCode() {
        if (!this.refs['code-input'].input.value) {
            return
        }
        let codeValue = this.refs['code-input'].input.value
        fetch(`/zhongcai/image/checkVerify?ver=${codeValue}&requestType=official_website`, {
            headers: new Headers({
                'requestType': 'official_website'
            })
        })
            .then(res => {
                return res.json()
            }).then(res => {
                if (!res) {
                    message.error('验证码输入错误,请重试', 3)
                    this.setState({
                        imgCodeFlag: false
                    })
                    return
                }
                this.setState({
                    imgCodeFlag: true
                })
            })
    }

    // 提交应聘信息
    submit() {
        let name = this.refs['name'].input.value
        let phone = this.refs['concat'].input.value
        console.log(this.state.fileAddress)
        if (!name) {
            message.error('请输入用户名', 3)
            return
        }
        if (!phone) {
            message.error('请输入联系方式', 3)
            return
        }
        if (!this.state.fileAddress) {
            message.error('请先上传简历附件', 3)
            return
        }
        if (!this.refs['code-input'].input.value) {
            message.error('请输入验证码', 3)
            return
        }
        if (!this.state.imgCodeFlag) {
            message.error('验证码输入错误，请重新输入', 3)
            return
        }
        this.setState({
            modalLoading: true
        })
        let json = {
            name,
            phone,
            resume: this.state.fileAddress
        }
        fetch('/zhongcai/apply_job', {
            method: 'POST',
            body: JSON.stringify(json),
            headers: new Headers({
                'Content-Type': 'application/json',
                'requestType': 'official_website'
            })
        }).then(res => {
            if (res >= 500) {
                message.error('服务器故障，请稍后重试', 4)
                return false
            }
            return res.json()
        }).then(res => {
            if (!res) {
                return
            }
            this.setState({
                success: true,
                modalLoading: false
            })
            message.success('提交应聘信息成功', 3)
            setTimeout(() => {
                this.closeModal()
            }, 2000)
        }).catch(e => {
            message.error('网络错误,请检查您的网络', 4)
        })
    }
    render() {
        const { title, time, content, num } = this.state
        return <div id="details">
            <Spin spinning={this.state.loading} size="large">
                <h3 className="title">{title}</h3>
                <p className='publish-time'>发布时间：<span>{time}</span></p>
                <div className="line-dashed"></div>
                <div>
                    <img src={this.state.image} />
                </div>
                <p className="require">职位要求</p>
                <div className="details-content">{content}</div>
                <p className='recruit'>招聘人数: {num}</p>

                <p>
                    <Button type="primary" onClick={this.showModal.bind(this)}>我要应聘</Button>
                </p>
                <div className="back-btn"><Button type="primary" onClick={this.back.bind(this)}>返回</Button></div>

                <div className="modal-box" style={{ display: this.state.closeModal ? 'flex' : 'none' }}>
                    <div className="modal-content">
                        <div className="modal-top">我要应聘</div>
                        <div className="modal-btm">
                            <div className="modal-item">
                                <span>姓名<i></i></span>
                                <Input placeholder="请输入您的姓名" ref='name' />
                            </div>
                            <div className="modal-item">
                                <span>联系电话<i></i></span>
                                <Input placeholder="请输入您的联系电话" ref='concat' />
                            </div>
                            <div className="modal-item">
                                <span>上传简历<i></i></span>
                                <div className='upload-content'>
                                    <Progress style={{ display: this.state.uploadProgress ? 'block' : 'none' }} type="circle" percent={this.state.uploadProgress} />
                                    <label htmlFor="file">
                                        <Icon type="plus" />
                                    </label>
                                    <input id="file" type="file" ref="fileInput" accept=".xls,.doc,.docx,.txt,.pdf" onChange={this.fileChange.bind(this)} />
                                    <div className="upload-tip">
                                        <span>（word、pdf格式）</span>
                                        <Button type="primary" style={{ display: this.state.uploadProgress === 100 ? 'none' : 'inline-block' }} icon="upload" onClick={this.uploadFile.bind(this)}>上传</Button>
                                        <Button type="primary" style={{ display: this.state.uploadProgress === 100 ? 'inline-block' : 'none' }} icon="delete" onClick={this.delUpload.bind(this)}>删除附件</Button>
                                    </div>
                                    <span className="preview-upload" style={{ display: this.state.isChooseFile ? 'flex' : 'none' }}>
                                        <span>{this.state.introduce}</span>
                                        <Icon type="close" onClick={this.cancelChoose.bind(this)} />
                                    </span>
                                </div>
                            </div>
                            <div className="modal-item code-box">
                                <span>验证码<i></i></span>
                                <div>
                                    <Input ref="code-input" onBlur={this.checkCode.bind(this)} />
                                    {/* <span>{this.state.checkCode}</span> */}
                                    <img src={this.state.imgCode} style={{ width: '95px', height: '25px' }} />
                                    <Button type="primary" onClick={this.changeCode.bind(this)} loading={this.state.btnLoading}>换一组</Button>
                                </div>
                            </div>
                            <div className="modal-item">
                                <Button type="primary" onClick={this.submit.bind(this)} loading={this.state.modalLoading}>提交</Button>
                                <Button onClick={this.closeModal.bind(this)}>返回</Button>
                            </div>
                            <p style={{ display: this.state.success ? 'block' : 'none' }}>提交完成,我们会与您取得联系</p>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    }
}
