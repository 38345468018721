import React, { Component } from 'react';
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Route } from 'react-router-dom';
import Management from '../../components/CheckUp/Management';
import Guide from '../../components/CheckUp/Guide';
import Library from '../../components/CheckUp/Library';
import Details from '../../components/Details'
import './index.css'
import bannerImg from '../../assets/images/zcy.jpg'
import Declare from '../../components/CheckUp/Declare'
import Review from '../../components/CheckUp/Review'
// import NewsDetails from '../NewsDetails/index';
class CheckUp extends Component {
  state={
      sideTitle: "仲裁鉴定",
      enSideTitle: 'Arbitration appraisal',
      sideList: [
        {
          title: '鉴定工作管理制度',
          enTitle: 'Management system of appraisal work',          
          link: '/CheckUp/management'
        },
        {
          title: '鉴定工作指引',
          enTitle: 'Service arbitrator',
          link: '/CheckUp/guide'
        },
        {
          title: '鉴定机构库',
          enTitle: 'Guidelines for appraisal work',
          link: '/CheckUp/library'
        },
        {
          title: '鉴定机构申报',
          enTitle: 'Online Declaration of Arbitration Institutions',
          link: '/CheckUp/declare'
        },
        {
          title: '鉴定机构年审',
          enTitle: 'Arbitration institution annual review',
          link: '/CheckUp/review'
        }
      ]
    }

  componentDidMount(){
    window.localStorage.setItem('url', '/CheckUp')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/CheckUp/management" render={() => (
        <Management isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/CheckUp/management" component={Management}></Route> 
    
  }
  render() {
    return <div id="check-up">
      <Header currentPage="/CheckUp"></Header>
      <Banner bannerTitle="仲裁鉴定" bannerEnTitle="Arbitration appraisal" type="6"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/CheckUp" component={Management} ></Route>
          {/* <Route exact path="/CheckUp/management" component={Management} ></Route> */}
          {this.renderScrollComponent()}
          <Route exact path="/CheckUp/guide" component={Guide} ></Route>
          <Route exact path="/CheckUp/library" component={Library} ></Route>
          <Route exact path="/CheckUp/declare" component={Declare} ></Route>
          <Route exact path="/CheckUp/review" component={Review} ></Route>
          <Route  path="/CheckUp/Details" component={Details} />
          {/* <Route exact path="/CheckUp/Details" component={Details} ></Route> */}
        </div>
      </div>   
      <Footer></Footer>     
    </div>
  }
}
export default CheckUp