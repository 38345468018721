import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import './index.css'
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index' ;
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import GuideParties from '../../components/Guide/GuideParties';
import Rules from '../../components/Guide/Rules';
import Process from '../../components/Guide/Process';
import Charge from '../../components/Guide/Charge';
import DownFile from '../../components/Guide/DownFile';
import Contracts from '../../components/Guide/Contracts';

import Question from '../../components/Guide/Question';
import Details from '../../components/Details';
import bannerImg from '../../assets/images/zczn.jpg'
class Guide extends Component {
  state={
      sideTitle: "仲裁指南",
      enSideTitle: 'Arbitration Guide',
      sideList: [
        {
          title: '当事人仲裁指南',
          enTitle: 'Guidelines for Parties to Arbitration',          
          link: '/Guide/guideParties'
        },
        {
          title: '仲裁规则',
          enTitle: 'Arbitration rules',
          link: '/Guide/rules'
        },
        {
          title: '仲裁流程',
          enTitle: 'Arbitration process',
          link: '/Guide/process'
        },
        {
          title: '仲裁收费',
          enTitle: 'Arbitration fees',
          link: '/Guide/charge'
        },
        {
          title: '文书下载',
          enTitle: 'Clericals download',
          link: '/Guide/downFile'
        },
        {
          title: '合同下载',
          enTitle: 'Contracts download',
          link: '/Guide/contracts'
        },
        {
          title: '常见问题',
          enTitle: 'Common problem',
          link: '/Guide/question'
        }
      ]
    }

  componentDidMount(){
    window.localStorage.setItem('url', '/Guide')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Guide/guideParties" render={() => (
        <GuideParties isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Guide/guideParties" component={GuideParties}></Route> 
    
  }
  render() {
    return <div id="guide">
      <Header currentPage="/Guide"></Header>
      <Banner bannerTitle="仲裁指南" bannerEnTitle="Arbitration Guide" type="3"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/Guide" component={GuideParties}></Route>
          <Route path="/Guide/rules" component={Rules}></Route>
          <Route path="/Guide/process" component={Process}></Route>
          <Route path="/Guide/charge" component={Charge}></Route>
          <Route path="/Guide/downFile" component={DownFile}></Route>
          <Route path="/Guide/contracts" component={Contracts}></Route>
          <Route path="/Guide/question" component={Question}></Route>
          {/* <Route path="/Guide/guideParties" component={GuideParties}></Route> */}
          {this.renderScrollComponent()}
          <Route path="/Guide/details" component={Details}></Route>
        </div>
      </div>      
      <Footer></Footer>  
    </div>
  }
}
export default Guide