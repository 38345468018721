import React, {Component} from 'react';
import { Carousel } from 'antd'
import { Ajax } from '../../util/ajax'
import prevUrl from '../../assets/images/prev.png';
import nextUrl from '../../assets/images/next.png';
import './index.css'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bannerList: []
    }
  }
  componentDidMount() {
    this.getBannerList()
  }
  getBannerList() {
    Ajax.GET(`/zhongcai/banner/queryByType?bannerType=${this.props.type}`)
    .then(res => {
      this.setState({
        bannerList: res.data.pageInfo.list
      })
    })
    .catch(err => {
      
    })
  }
  renderBanner() {
    let bannerList = this.state.bannerList
    return bannerList.map(item => {
      return <img src={item.imgUrl} alt=''  key={item.id}/>
    })
  }
  prev() {
    this.refs.carousel.prev()
  }
  next() {
    this.refs.carousel.next()
  }
  render() {
    return <div id="bannerUrl">
        {/* {this.renderBanner()}
        <div className="bannerBox">
          <div className="fontCn">{this.props.bannerTitle}</div>
          <div className="fontEn">{this.props.bannerEnTitle}</div>
        </div> */}
        <div className="carousel">
          <Carousel autoplay dots="false" ref='carousel'>
            {/* <div className="carousel-img-content"><img src={imgUrl} alt=""/></div>
            <div className="carousel-img-content"><img src={bannerUrl} alt=""/></div> */}
            {this.renderBanner()}
          </Carousel>
          <div className={`lbt-btn  ${this.state.bannerList.length<=1? 'banner-hide' : ''}`} >
            <img src={prevUrl} alt="" onClick={this.prev.bind(this)}/>
            <img src={nextUrl} alt="" onClick={this.next.bind(this)}/>
          </div>
        </div>
    </div>
  }
}
export default App