import React, {Component} from 'react';
import './index.css'
import Banner from '../../components/Banner/index'
import SideBar from '../../components/SideBar/index' 
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Route } from 'react-router-dom'
import ChargeFastCalculate from '../../components/OnlineSupport/ChargeFastCalculate/index'
// import Application from '../../components/OnlineSupport/application/index'
import Complaints from '../../components/OnlineSupport/complaints/index'
import Guide from '../../components/OnlineSupport/guide/index'
import JoinUs from '../../components/OnlineSupport/joinUs/index'
import Specialist from '../../components/OnlineSupport/specialist/index'
// import Details from '../../components/JoinUsDetail';
import JoinUsDetails from '../../components/JoinUsDetail';
import Details from '../../components/Details';
// import bannerImg from '../../assets/images/dj.png'
class FastBooting extends Component {
  state={
      sideTitle: "在线服务",
      enSideTitle: 'Online services',
      sideList: [
        {
          title: '申请仲裁指南',
          enTitle: 'Online arbitration application guide',
          link: '/FastBooting/Guide'
        },
        // {
        //   title: '申请仲裁',
        //   enTitle: 'Online application for arbitration',
        //   link: '',
        //   href: 'http://www.baidu.com'
        //   // link: '/FastBooting/Application'
        // },        
        {
          title: '申请专家',
          enTitle: 'Online application specialist',
          // link: '/FastBooting/Specialist'
          link: '/FastBooting/specialist'
        },
        {
          title: '费用快算',
          enTitle: 'Charge fast calculate',
          link: '/FastBooting/ChargeFastCalculate'
        },
        {
          title: '反馈投诉',
          enTitle: 'Feedback complaints',
          link: '/FastBooting/Complaints'
        },
        {
          title: '加入我们',
          enTitle: 'Join us',
          link: '/FastBooting/JoinUs'
        }
      ]
    }
  componentDidMount(){
    window.localStorage.setItem('url', '/FastBooting')
  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/FastBooting/Guide" render={() => (
        <Guide isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/FastBooting/Guide" component={Guide}></Route> 
    
  }
  render() {
    return <div id="FastBooting">
      <Header currentPage="/FastBooting"></Header>
      <Banner bannerTitle="在线服务" bannerEnTitle="Online services" type="5"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          {/* <div className="App"> */}
            <Route exact path="/FastBooting" component={ChargeFastCalculate} />
            <Route  path="/FastBooting/ChargeFastCalculate" component={ChargeFastCalculate} />
            {/* <Route  path="/FastBooting/Application" component={Application} /> */}
            <Route  path="/FastBooting/Complaints" component={Complaints} />
            {/* <Route  path="/FastBooting/Guide" component={Guide} /> */}
            {this.renderScrollComponent()}
            <Route  path="/FastBooting/JoinUs" component={JoinUs} />
            <Route  path="/FastBooting/specialist" component={Specialist} />
            <Route  path="/FastBooting/Details" component={Details} />
          {/* </div>         */}
        </div>
      </div> 
      <Footer></Footer>
    </div>
  }
}
export default FastBooting