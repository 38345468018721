import React, { Component } from 'react';
import { Pagination } from 'antd';
import MenuItem from '../../MenuItem'
import './index.css';
import '../../../assets/common.css';
import { Spin, message } from 'antd';
// import Notice from '../Notice'
import store from '../../../redux/store'
// const TabPane = Tabs.TabPane;

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      pageSize: 5,
      total: 5,
      pageNum: 1,
      activeKey: "1"
    }
  }
  getData(pageSize, page) {
    fetch('/zhongcai/information/searchShow/?pid=1&sid=15&pageSize=' + pageSize + '&pageNum=' + page,{
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return
      }
      let data = res.data.pageInfo
      if(data.list.length !== 0) {
        this.setState({
          list: data.list,
          loading: false,
          total: data.total
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    
    if(this.props.location.query && this.props.location.query.type === 'noticeList') {
      this.setState({
        activeKey: "2"
      })
    }
    this.getData(this.state.pageSize, this.state.pageNum)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '仲裁资讯'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
  }
  renderItem() {
    let list = this.state.list;
    return list.map((item, index) => {
      return <MenuItem key={index} content={item.title} time={item.createat} urlId={item.id} dir="/Mien"></MenuItem>
    })
  }

  onChange(page, pageSize) {
    this.getData(pageSize, page)
  }
  //tab切换回调
  // callback(key) {
  //   this.setState({
  //     activeKey: key
  //   })
  // }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id="news-list" ref="ele">
        {/* <Tabs activeKey={this.state.activeKey} onChange={this.callback.bind(this)}>
          <TabPane tab="仲裁资讯" key="1"> */}
            <div className="allSameBox">
              {this.renderItem()}
            </div>
            <div className='pagination' id="common">
              <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
            </div>
          {/* </TabPane>
          <TabPane tab="长仲公告" key="2">
            <Notice></Notice>
          </TabPane>
        </Tabs> */}
      </div>    
    </Spin>
  }
}
export default NewsList