import React, { Component } from 'react';
import { Input, Select, Table, Button, Icon, message, Spin } from 'antd';
import './index.css'
import store from '../../../redux/store'
const InputGroup = Input.Group;
const Option = Select.Option;
const Search = Input.Search;

class Register extends Component {
  state = {
    dataSource: [],
    datatable: [],
    pageSize: 10,
    pageNum: 1,
    total: 10,
    findType: 1,
    findValue: '',
    nowkey: '1',
    loading: false,
    sortOrderArr: [{
      key: 'name',
      value: 'asc'
    }, {
      key: 'gender',
      value: 'asc'
    }, {
      key: 'technicalTitle',
      value: 'asc'
    }, {
      key: 'profession',
      value: 'asc'
    }, {
      key: 'address',
      value: 'asc'
    }, {
      key: 'careerClass',
      value: 'asc'
    },{
      key: 'foreignLanguage',
      value: 'asc'
    }],
    filtSortName: '',
    filtSortOrder: ''
  }
  componentDidMount() {
    this.showAll(this.state.pageSize, this.state.pageNum, this.state.findType)
    // store.dispatch({
    //   type: 'CHANGE_NAV',
    //   payload: '金融仲裁员'
    // })
    // let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    // setTimeout(() => {
    //   document.documentElement.scrollTop = h
    // }, 0)
  }
  onSearch(value) {
    if (!value) {
      message.error('搜索内容不能为空!', 3)
      return false
    }
    this.setState({
      findValue: value
    })
    this.showAll(this.state.pageSize, 1, this.state.findType, value, this.state.filtSortName, this.state.filtSortOrder)
    //todo  根据 姓名专业居住地查找
  }

  //显示全部
  showAll(pageSize, pageNum, type = '', str = '', sortName, sortOrder, isAll = false) {
    this.setState({
      loading: true
    })
    if (isAll) {
      this.setState({
        findValue: ''
      })
      document.querySelector('.ant-input.ant-input-lg').value = ""
    }
    // let url = '/zhongcai/roster/?pageSize=' + pageSize + '&pageNum=' + pageNum + '&groupClass=' + encodeURIComponent('金融仲裁员'); 
    // if(type !== '' && str !== '') {
    //   url = '/zhongcai/roster/?pageSize=' + pageSize + '&pageNum=' + pageNum + '&groupClass=' + encodeURIComponent('金融仲裁员') + '&findType=' + encodeURIComponent(type) + '&findStr=' + encodeURIComponent(str);
    // }

    let url = '';
    url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + type + '&str=' + encodeURIComponent(str) + '&groupClass=' + '金融仲裁员'
    if (sortName) {
      url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + type + '&str=' + encodeURIComponent(str) + '&sortName=' + sortName + '&sortOrder=' + sortOrder + '&groupClass=' + '金融仲裁员'
    } else {
      url = '/zhongcai/roster/queryBySortName?pageSize=' + pageSize + '&pageNum=' + pageNum + '&findType=' + type + '&str=' + encodeURIComponent(str) + '&sortName=' + 'name,technicalTitle,profession,address,careerClass,gender,foreignLanguage' + '&sortOrder=' + 'asc,asc,asc,asc,asc,asc,asc' + '&groupClass=' + '金融仲裁员'
    }
    fetch(url, {
      method: "GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res) => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if (!res) {
        return false;
      }
      this.setState({
        loading: false
      })
      let arr = []
      let data = res.data.pageInfo.list

      data.forEach((item, index) => {
        let obj = {
          key: item.id,
          name: item.name,
          gender: item.gender === 0 ? "女" : "男",
          technicalTitle: item.technicalTitle,
          profession: item.profession,
          address: item.address,
          careerClass: item.careerClass,
          foreignLanguage: item.foreignLanguage,
          index: pageNum - 1 > 0 ? ((pageNum - 1) * pageSize + index + 1) : index + 1
        }
        arr[index] = obj
      });
      this.setState({
        datatable: arr,
        total: res.data.pageInfo.total,
        pageNum: res.data.pageInfo.pageNum
      }, () => {
        console.log(this.state.total)
      })
    })
  }


  handleChange(e) {
    console.log(e)
    this.setState({
      findType: e
    })
  }
  pageChange(obj, filter, sorter) {
    let index;
    let thisItem;
    let sortOrderArr = this.state.sortOrderArr.map((item, idx, arr) => {
      if (item.key === sorter.columnKey) {
        item.value = sorter.order == 'ascend' ? 'asc' : 'desc';
        index = idx
        thisItem = item
      }
      return item;
    });
    if (index) {
      sortOrderArr.splice(index, 1);
      sortOrderArr.unshift(thisItem);
    };

    let sortNameArr = []
    let sortOrder = []
    sortOrderArr.forEach((item, idx, arr) => {
      sortNameArr.push(item.key)
      sortOrder.push(item.value)
    })

    const filtSortName = sortNameArr.join(',')
    const filtSortOrder = sortOrder.join(',')
    this.setState({
      sortOrderArr: sortOrderArr,
      filtSortName: filtSortName,
      filtSortOrder: filtSortOrder
    }, () => {
      this.showAll(obj.pageSize, obj.current, this.state.findType, this.state.findValue, this.state.filtSortName, this.state.filtSortOrder)
    })
  }
  render() {
    const columns = [{
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80
    }, {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 90
    }, {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      width: 90
    },
    {
      title: '技术职称',
      dataIndex: 'technicalTitle',
      key: 'technicalTitle',
      sorter: true,
      width: 140
    },
    {
      title: '专业',
      dataIndex: 'profession',
      key: 'profession',
      sorter: true,
      width: 170
    }, {
      title: '居住地',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      width: 100
    }, {
      title: '职业分类',
      key: 'careerClass',
      dataIndex: 'careerClass',
      sorter: true,
      width: 120
    },{
      title: '外语',
      key: 'foreignLanguage',
      dataIndex: 'foreignLanguage',
      sorter: true,
      width: 90
    }];
    const data = this.state.datatable
    return <div id="register" ref="ele">
      <InputGroup
        compact
        style={{ marginBottom: "31px" }}
      >
        <div className='label'>金融仲裁员推荐名册查询:</div>
        <Select defaultValue="姓名" onChange={this.handleChange.bind(this)} className="selectBox" size='large' suffixIcon={<Icon type="caret-down" />}>
          <Option value="1" key="1">姓名</Option>
          <Option value="2" key="2">专业</Option>
          <Option value="3" key="3">居住地</Option>
        </Select>
        <Search
          placeholder="search"
          onSearch={this.onSearch.bind(this)}
          style={{ width: 330 }}
          size='large'
        />

        <Button className="buttonBox" onClick={this.showAll.bind(this, 10, 1, this.state.findType, '', '', '', true)}>显示全部</Button>
      </InputGroup>
      <Spin spinning={this.state.loading}>
        <Table locale={{ emptyText: '暂无数据' }} columns={columns} dataSource={data} rowClassName="rowrua" bordered pagination={{ total: this.state.total, current: this.state.pageNum }} onChange={this.pageChange.bind(this)} />
      </Spin>
    </div>
  }
}
export default Register