import React, { Component } from 'react';
import './index.css'
import Details from '../../Details'
import MenuItem from '../../MenuItem'
import { Spin, message, Pagination } from 'antd';
import store from '../../../redux/store'
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      time: '',
      content: '',
      loading: true,
      source: '',
      list: [],
      loading: true,
      pageSize: 5,
      total: 5,
      pageNum: 1
    }
  }
  // getData() {
  //   fetch('/zhongcai/information/?pid=4&sid=26', {//通过pid以及sid获取到当前类目的id  
  //     method: "GET",
  //     headers: new Headers({
  //       'requestType': 'official_website'
  //     })
  //   }).then((res) => {
  //     if (res.status >= 500) {
  //       message.error('服务器出错，请稍后重试', 3)
  //       return false
  //     }
  //     return res.json();
  //   }).then(res => {
  //     if (!res) {
  //       return
  //     }
  //     if (res.data.pageInfo.list.length !== 0) {
  //       let id = res.data.pageInfo.list[0].id
  //       id && fetch('/zhongcai/information/' + id, {
  //         method: 'GET',
  //         headers: new Headers({
  //           'requestType': 'official_website'
  //         })
  //       }).then(res => {
  //         return res.json()
  //       }).then(res => {
  //         let data = res.data.information
  //         if (data.shows === 1) {
  //           message.error("该数据已被管理员修改为不展示！", 5);
  //           this.setState({
  //             loading: false
  //           })
  //           return false
  //         }
  //         this.setState({
  //           title: data.title,
  //           time: data.createat,
  //           content: data.content,
  //           source: data.source,
  //           loading: false
  //         })
  //       })
  //     } else {
  //       message.warning("暂无数据，请前往后台添加数据后刷新", 3)
  //       this.setState({
  //         loading: false
  //       })
  //     }
  //   })
  // }
  getData(pageSize, page) {
    fetch('/zhongcai/information/?pid=4&sid=26&pageSize=' + pageSize + '&pageNum=' + page, {
      method: "GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res) => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if (!res) {
        return
      }
      let data = res.data.pageInfo
      if (data.list.length !== 0) {
        this.setState({
          list: data.list,
          loading: false,
          total: data.total
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '在线申请仲裁指南'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    if (this.props.isScroll === false) {

    } else {
      setTimeout(() => {
        document.documentElement.scrollTop = h;
      }, 0)
    }
  }
  renderItem() {
    let list = this.state.list;
    return list.map((item, index) => {
      return <MenuItem key={index} content={item.title} time={item.createat} urlId={item.id} dir="/FastBooting"></MenuItem>
    })
  }
  onChange(page, pageSize) {
    this.getData(pageSize, page)
  }
  render() {
    // return <Spin spinning={this.state.loading} size="large">
    //   <div id='guide' ref="ele">        
    //       {this.state.title && <Details title={this.state.title}  time={this.state.time} content={this.state.content} source={this.state.source}></Details>}
    //   </div>
    // </Spin>
    return <Spin spinning={this.state.loading} size="large">
      <div id="member" ref="ele">
        <div className="allSameBox">
          {this.renderItem()}
        </div>
        <div className='pagination' id="common">
          <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
        </div>
      </div>
    </Spin>
  }
}
export default App