import React, { Component } from 'react';
import Header from '../../components/Header/index'
import Footer from '../../components/Footer/index'
import { withRouter } from 'react-router-dom'
import Details from '../../components/Details'
import './index.css';
class SearchDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detailId: ''
    }
  }
  componentDidMount() {
    this.props.location.state && this.setState({
      detailId: this.props.location.state.itemId
    })
  }

  render() {
    return  <div id="search-detail">
        <Header></Header>
          {this.state.detailId && <Details id={this.state.detailId}></Details>}
        <Footer></Footer>
      </div>
  }
}
export default withRouter(SearchDetail)