import React, { Component } from 'react';
import './index.css'
import store from '../../../redux/store'
class App extends Component {
  state = {
    //争议金额
    shouldToPay: 0,
    //处理费
    mustToPay: 0,
    //行为收费
    mycount: 0,
    addmount: 20
  }
  back() {
    window.history.back()
  }
  addClass(e) {
    let reg = /changeButtonActive/g
    if (!e.target.className.match(reg)) {
      this.refs['change1'].className = 'changeButton'
      this.refs['change2'].className = 'changeButton'
      e.target.className = "changeButton changeButtonActive"
      let addmount = parseInt(e.target.getAttribute('datakey'))
      // if (addmount === 1) {
      //   this.setState({
      //     addmount: 15
      //   })
      // } else {
      //   this.setState({
      //     addmount: 20
      //   })
      // }
    }
  }
  componentDidMount() {
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '费用快算'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
  }
  //算钱的  注释暂时别删
  count() {
    //第一个数
    let count1 = Number(this.refs['count1'].value);
    //第二个数
    let count2 = Math.round(Number(this.refs['count2'].value));
    if (!count2) {
      count2 = 0;
    } else {
      count2 =Math.round(Number(this.refs['count2'].value));
    }
    // if(!count1 || !count2) return
    let changeNumber = 0;
    let mustToPayNum = 0;
    let totalPayNum = 0;

    //原来计算逻辑
    // if(count1<=1000){
    //     changeNumber=50
    // }else if(1000<count1&&count1<=50000){
    //     changeNumber=50+(count1-1000)/100*4
    // }else if(50000<count1&&count1<=100000){
    //     changeNumber=50+49000/100*4+(count1-50000)/100*3
    // }else if(100000<count1&&count1<=200000){
    //     changeNumber=50+49000/100*4+50000/100*3+(count1-100000)/100*2
    // }else if(200000<count1&&count1<=500000){
    //     changeNumber=50+49000/100*4+50000/100*3+100000/100*2+(count1-200000)/1000*15
    // }else if(500000<count1&&count1<=1000000){
    //     changeNumber=50+49000/100*4+50000/100*3+100000/100*2+300000/1000*15+(count1-500000)/100*1
    // }else if(1000000<count1){
    //     changeNumber=50+49000/100*4+50000/100*3+100000/100*2+300000/1000*15+500000/100*1+(count1-1000000)/1000*5
    // }


    if (count1 <= 1000) {
      changeNumber = 50
    } else if (1000 < count1 && count1 <= 50000) {
      changeNumber = 50 + (((count1 - 1000) / 100) * 4);
    } else if (50000 < count1 && count1 <= 100000) {
      changeNumber = 2010 + (((count1 - 50000) / 100) * 3);
    } else if (100000 < count1 && count1 <= 200000) {
      changeNumber = 3510 + (((count1 - 100000) / 100) * 2);
    } else if (200000 < count1 && count1 <= 500000) {
      changeNumber = 5510 + (((count1 - 200000) / 1000) * 15);
    } else if (500000 < count1 && count1 <= 1000000) {
      changeNumber = 10010 + (((count1 - 500000) / 100) * 1);
    } else if (1000000 < count1) {
      changeNumber = 15010 + (((count1 - 1000000) / 1000) * 5);
    }
    // 受理费
    totalPayNum = count2 + Math.round(changeNumber);
    // 确认行为应收费
    mustToPayNum = Math.round((totalPayNum) / 100 * this.state.addmount);
    if (mustToPayNum < 500) {
      mustToPayNum = 500;
    }
    this.setState({
      shouldToPay: Number(totalPayNum),
      mustToPay: Number(mustToPayNum),
      mycount: Number(count2)
    })
  }
  recount() {
    this.refs['count1'].value = ""
    this.refs['count2'].value = ""
    this.setState({
      shouldToPay: 0.00,
      mustToPay: 0.00,
      mycount: 0.00
    })
  }


  render() {
    return <div id="ChargeFastCalculate" ref="ele">
      <div className="allSameBox">
        <div className='countBox'>
          <div className='countFont'>双方的争议金额(元)</div>
          <div className='countButton' style={{ textAlign: "center" }}>
            <input type='number' placeholder="最多15位数" className='countInput' ref='count1'></input>
          </div>
        </div>
        <div className='countBox'>
          <div className='countFont'>确认行为应收费(元)</div>
          <div className='countButton' style={{ textAlign: "center" }}>
            <input type='number' placeholder="最多15位数" className='countInput' ref='count2'></input>
          </div>
        </div>
        <div className='btn'>
          <button className='numButton onnum' onClick={this.count.bind(this)}>计算</button>
          <button className='numButton' onClick={this.recount.bind(this)}>重算</button>
        </div>
        <p className='countResult'>计算结果</p>
        <div className='solid'></div>

        <div className="myAllCount">
          <div className="clildmyCount">
            <p>应交受理费&nbsp; (元)</p>
            <p>{this.state.shouldToPay}</p>
          </div>
          <p style={{ lineHeight: '4', fontSize: "24px" }}>+</p>
          <div className="clildmyCount">
            <p>应交处理费&nbsp; (元)</p>
            <p>{this.state.mustToPay}</p>
          </div>
          <p style={{ lineHeight: '4', fontSize: "24px" }}>=</p>
          <div className="clildmyCount">
            <p>共计&nbsp; (元)</p>
            <p style={{ color: "#cc0000" }}>{this.state.shouldToPay + this.state.mustToPay}</p>
          </div>
        </div>
      </div>
    </div>
  }
}
export default App
