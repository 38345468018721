import React, { Component } from 'react';
import Header from '../../components/Header/index'
import Footer from '../../components/Footer/index'
import { Pagination, Spin, message } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import store from '../../redux/store'
import './index.css';
class SearchResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total: 5,
      pageSize: 10,
      pageNum: 1,
      loading: true,
      searchText: store.getState().searchText.trim(),
      listData: []
    }
  }
  componentDidMount() {
    this.showAll(this.state.pageSize, this.state.pageNum, this.state.searchText)
  }
  showAll(pageSize, pageNum, title) {
    this.setState({
      loading: true
    })
    fetch(`/zhongcai/information/title?content=${title}&title=${title}&pageSize=${pageSize}&pageNum=${pageNum}`, {
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return
      }
      this.setState({
        loading: false,
        listData: res.list,
        total: res.total,
        pageNum: res.pageNum,
        searchText: store.getState().searchText.trim()
      })
    })
  }
  headerSearch() {
    this.showAll(this.state.pageSize, 1, store.getState().searchText.trim())
  }
  renderItem() {
    return this.state.listData.map((item, index) => {
      return <Link to={{pathname: '/SearchDetail', state: {itemId: item.id}}} key={index}>
        <div className="result-item">
          <span className="item-pointer"></span>
          <li className="liInfo">{item.title}</li>
          <li className='infoTime'>{item.createat}</li>
        </div>
      </Link>
    })
  }
  onChange(page, pageSize) {
    this.setState({
      pageSize,
      pageNum: page
    })
    this.showAll(pageSize, page, store.getState().searchText.trim())
  }
  render() {
    return  <Spin spinning={this.state.loading} size="large">
      <div id="search-result">
        <Header searchFn={this.headerSearch.bind(this)}></Header>
        <div className="search-content">
          <div className="search-box">
            <div className="result-info">搜索"<span className="result-red-text">{this.state.searchText}</span>"  共"<span className="result-red-text">{this.state.total}</span>"条，包含标题和文章</div>
            <div className="result-list">
              {this.renderItem()}             
            </div>
            <Pagination pageSize={this.state.pageSize} current={this.state.pageNum} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
          </div>        
        </div>
        <Footer></Footer>
      </div>
    </Spin>
  }
}
export default withRouter(SearchResult)