/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-04 20:02:14
 * @LastEditTime: 2019-08-27 10:03:13
 * @LastEditors: Please set LastEditors
 */
import React, { Component } from 'react';
import { Carousel, Icon, message, Spin } from 'antd';
import '../../redux/store'
import { Link } from 'react-router-dom'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './index.css';
import '../../assets/iconfont/iconfont.css'
import { Ajax } from '../../util/ajax'
import imgUrl from '../../assets/images/lbt.jpg';
import prevUrl from '../../assets/images/prev.png';
import nextUrl from '../../assets/images/next.png';
import editUrl from '../../assets/images/edit.png';
import attendUrl from '../../assets/images/attend.png';
import bookUrl from '../../assets/images/book.png';
import stepUrl from '../../assets/images/step.png';
import priceUrl from '../../assets/images/price.png';
import downloadUrl from '../../assets/images/download.png';
import questionUrl from '../../assets/images/question.png';
import feedbackUrl from '../../assets/images/feedback.png';
import personUrl from '../../assets/images/person.png';
import zcUrl from '../../assets/images/zhongcai.png';
import homeUrl from '../../assets/images/home.png';
import quanUrl from '../../assets/images/quan.png';
import pointUrl from '../../assets/images/point.png';
import code1Url from '../../assets/images/code1.png';
import code2Url from '../../assets/images/code2.png';
import bannerUrl from '../../assets/images/zczn.jpg'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lbtIndex: 0,
      showModal: true,
      newsList: [],//最新资讯列表
      imgNewsList: [],//带封面的资讯列表
      noticeList: [],//最新公告列表
      newsTitle: '',//轮播新闻标题
      loading: true,
      bannerList: [],
      isload: false,
      bannerLength: 0,
    }
  }
  prev() {
    this.refs.carousel.prev()
  }
  next() {
    this.refs.carousel.next()
  }
  prevBtm() {
    this.refs.carouselBtm.prev()
  }
  nextBtm() {
    this.refs.carouselBtm.next()
  }
  onChange(index) {
    this.setState({
      lbtIndex: index,
      newsTitle: this.state.imgNewsList[index] && this.state.imgNewsList[index].title
    })
  }
  componentDidMount() {
    this.getBannerList()
    this.getNewsData()
    this.getImgNews()
    this.getNotice()
  }
  /**
   * 获取首页banner图
   */
  getBannerList() {
    Ajax.GET(`/zhongcai/banner/queryByType?bannerType=1`)
      .then(res => {
        this.setState({
          bannerList: res.data.pageInfo.list
        })
      })
      .catch(err => {

      })
  }
  //获取带封面的资讯
  getImgNews() {
    fetch('/zhongcai/information/images', {
      method: "GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then(res => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json()
    }).then(res => {
      res && this.setState({
        imgNewsList: res.data.result,
        newsTitle: res.data.result[0].title,
        loading: false
      })
    })
  }
  //获取最新资讯
  getNewsData() {
    fetch('/zhongcai/information/?pid=1&sid=15&pageSize=3', {
      method: "GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res) => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      res && this.setState({
        newsList: res.data.pageInfo.list
      })
    })
  }
  //获取最新公告
  getNotice() {
    fetch('/zhongcai/information/?pid=1&sid=37&pageSize=3', {
      method: "GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res) => {
      if (res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      res && this.setState({
        noticeList: res.data.pageInfo.list
      })
    })
  }
  renderBanner() {
    let bannerList = this.state.bannerList
    const style = { "opacity": this.state.isload ? 1 : 0, transition: '0.5s' }
    return bannerList.map(item => {
      return <div key={item.id} className="carousel-img-content"><img src={item.imgUrl} style={style}
        onLoad={e => {
          this.setState({
            isload: true
          })
        }}
        alt="" /></div>
    })
  }
  renderImgNews() {
    let list = this.state.imgNewsList;
    return list.map((item, index) => {
      return <Link to={
        {
          pathname: "/Mien/Details",
          state: {
            id: item.id
          }
        }
      } key={item.id}>
        <div className="news-banner-img" style={{ position: 'relative' }}>
          <img className="news-banner-img" src={item.image} alt="" />
          <div className="home-news-carousel-btm" style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
          }}>
            {this.state.newsTitle}
          </div>
        </div>
      </Link>
    })
  }
  renderNoticeItem() {
    let list = this.state.noticeList;
    return list.map((item, index) => {
      return <Link to={
        {
          pathname: "/Mien/Details",
          state: {
            id: item.id
          }
        }
      } key={index}>
        <div className="home-news-right-item">
          <span className="news-latest"></span>
          <span className="news-title">{item.title}</span>
        </div>
      </Link>
    })
  }
  renderNews() {
    let list = this.state.newsList;
    return list.map((item, index) => {
      return <Link to={
        {
          pathname: "/Mien/Details",
          state: {
            id: item.id
          }
        }
      } key={index}>
        <div className="home-news-right-item">
          <span className="news-time">{item.createat.split(' ')[0]}</span>
          <span className="news-title">{item.title}</span>
        </div>
      </Link>
    })
  }
  //渲染轮播图下方的序列点
  renderPoint() {
    let list = this.state.imgNewsList;
    return list.map((item, index) => {
      return <li style={{ opacity: this.state.lbtIndex === index ? '1' : '0.7' }} key={index}></li>
    })
  }
  closeModal() {
    this.setState({
      showModal: false
    })
  }

  render() {
    return <div id="home">
      <Header currentPage="/"></Header>
      {/* banner轮播图 */}
      <div className="carousel">
        <Carousel autoplay dots="false" ref='carousel'>
          {/* <div className="carousel-img-content"><img src={imgUrl} alt=""/></div>
          <div className="carousel-img-content"><img src={bannerUrl} alt=""/></div> */}
          {this.renderBanner()}
        </Carousel>
        <div className={`lbt-btn  ${this.state.bannerList.length <= 1 ? 'banner-hide' : ''}`} >
          <img src={prevUrl} alt="" onClick={this.prev.bind(this)} />
          <img src={nextUrl} alt="" onClick={this.next.bind(this)} />
        </div>
        <div className="carousel-text">
          <h1>诚信公正 用心服务</h1>
          <p>Integrity, justice and serving with heart</p>
          <div className="carousel-text-btns">
            <a href="http://zc.cscac.net/app/createcase/intro" target="_blank" rel="noopener noreferrer">
              <p>
                <img src={editUrl} alt="" />
                <span>立案申请</span>
              </p>
            </a>

            <a href="http://zc.cscac.net/app/mycase/list" target="_blank" rel="noopener noreferrer">
              <p>
                <img src={attendUrl} alt="" />
                <span>参加仲裁</span>
              </p>
            </a>

            <a href="https://cscac.yuntrial.com/portal/main/domain/index.htm?pagekey=trial" target="_blank" rel="noopener noreferrer">
              <p>
                <img src="https://img.alicdn.com/imgextra/i4/O1CN01P1IbMd1Y75IkRZQwH_!!6000000003011-2-tps-64-66.png" alt="" />
                <span>在线庭审</span>
              </p>
            </a>
          </div>
        </div>
      </div>

      {/* 首页新闻区域 */}
      <div className="home-news">
        <div className="home-news-box">
          <Spin spinning={this.state.loading}>
            <div className="home-news-carousel">
              {this.state.imgNewsList && this.state.imgNewsList.length && <Carousel autoplay dots={false} ref='carouselBtm' afterChange={this.onChange.bind(this)}>
                {
                  this.state.imgNewsList.map((item, index) => {
                    return <Link to={
                      {
                        pathname: "/Mien/Details",
                        state: {
                          id: item.id
                        }
                      }
                    } key={item.id}>
                      <div className="news-banner-img" style={{ position: 'relative' }}>
                        <img className="news-banner-img" src={item.image} alt="" />
                        <div className="home-news-carousel-btm" style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                        }}>
                          {this.state.newsTitle}
                        </div>
                      </div>
                    </Link>
                  })
                }
              </Carousel>}
              <div className={`lbt-btn  ${this.state.imgNewsList.length <= 1 ? 'banner-hide' : ''}`} >
                <img src={prevUrl} alt="" onClick={this.prevBtm.bind(this)} />
                <img src={nextUrl} alt="" onClick={this.nextBtm.bind(this)} />
              </div>
              <ul className="dots-list">
                {this.renderPoint()}
              </ul>

            </div>
          </Spin>
          <div className="home-news-right">
            <div className="home-news-right-top">
              <div className="home-news-right-title">
                <p>最新动态</p>
                <p>
                  <span>Latest News</span>
                  <span><Link to='/Mien/newslist'>更多<Icon type="right" /></Link></span>
                </p>
              </div>
              <div className="home-news-right-content">
                {this.renderNews()}
              </div>
            </div>
            <div className="home-news-right-btm">
              <div className="home-news-right-title">
                <p>最新公告</p>
                <p>
                  <span>Latest Announcements</span>
                  <span><Link to={{ pathname: '/Mien/notice', query: { type: 'noticeList' } }}>更多<Icon type="right" /></Link></span>
                </p>
              </div>
              <div className="home-news-right-content">
                {this.renderNoticeItem()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 便民 */}
      <div className="home-convenient">
        <div className="home-convenient-box">
          <div className="home-same-top">
            <p>便民功能区</p>
            <p>
              <span>Convenient function area</span>
            </p>
          </div>
          <div className="home-convenient-list">
            <Link to="/Mien/aboutUs">
              <div className="home-convenient-item">
                <div>
                  <img src={bookUrl} alt="" />
                </div>
                <span>关于我们</span>
              </div>
            </Link>
            <Link to="/Guide/process">
              <div className="home-convenient-item">
                <div>
                  <img src={stepUrl} alt="" />
                </div>
                <span>仲裁流程</span>
              </div>
            </Link>
            <Link to="/FastBooting/ChargeFastCalculate">
              <div className="home-convenient-item">
                <div>
                  <img src={priceUrl} alt="" />
                </div>
                <span>费用快算</span>
              </div>
            </Link>
            <Link to="/Guide/downFile">
              <div className="home-convenient-item">
                <div>
                  <img src={downloadUrl} alt="" />
                </div>
                <span>文书下载</span>
              </div>
            </Link>
            <Link to="/Guide/contracts">
              <div className="home-convenient-item">
                <div>
                  <img src={downloadUrl} alt="" />
                </div>
                <span>合同下载</span>
              </div>
            </Link>
            <Link to="/Guide/question">
              <div className="home-convenient-item">
                <div>
                  <img src={questionUrl} alt="" />
                </div>
                <span>常见问题</span>
              </div>
            </Link>
            <Link to="/FastBooting/Complaints">
              <div className="home-convenient-item">
                <div>
                  <img src={feedbackUrl} alt="" />
                </div>
                <span>反馈投诉</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* 平台 */}
      <div className="home-platform">
        <div className="platform-content">
          <div className="platform-content-left">
            <div className="home-same-top">
              <p>仲裁服务平台</p>
              <p>
                <span>Arbitration service platform</span>
              </p>
            </div>
            <div className="platform-list">
              <Link to="/Guide/guideParties" className="platform-item">
                <div className="platform-item-img"><img src={personUrl} alt="" /></div>
                <div className="platform-item-info">
                  <span>当事人服务平台</span>
                  <span>Client service platform</span>
                </div>
              </Link>
              <Link to="/Arbitrator/service" className="platform-item">
                <div className="platform-item-img"><img src={zcUrl} alt="" /></div>
                <div className="platform-item-info">
                  <span>仲裁员服务平台</span>
                  <span>Arbitrator service platform</span>
                </div>
              </Link>
              <Link to="/CheckUp/guide" className="platform-item">
                <div className="platform-item-img"><img src={homeUrl} alt="" /></div>
                <div className="platform-item-info">
                  <span>鉴定机构服务平台</span>
                  <span>Identify agency service platform</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="platform-content-right">
            <div className="home-same-top">
              <p>仲裁示范条款</p>
              <p>
                <span>Arbitration model clause</span>
              </p>
            </div>
            <div className="platform-content-right-text">
              <p className="platform-zh">因本合同引起的或与本合同有关的任何争议，本合同各方当事人均同意提请长沙仲裁委员会（长沙国际仲裁中心）按照其仲裁规则进行仲裁。仲裁结果对本合同各当事人均有约束力。</p>
              <p className="platform-en">For any dispute arising from or in connection with this Contract,the parties agree to apply to the Changsha Arbitration Commission(Changsha International Arbitration Commission) for arbitration which shall be conducted in accordance with the Commission's rules. The arbitration awards are binding on the parties.</p>
            </div>
          </div>
        </div>
      </div>

      {/* 友链 */}
      <div className="home-friendly">
        <div className="home-friendly-top">
          <div className="home-same-top">
            <p><a className="otherLink" href='http://www.chinalaw.gov.cn/' target="_blank" >其他链接</a></p>
            <p>
              <span>Links</span>
            </p>
          </div>
        </div>
        <div className="home-friendly-content" >
          <Carousel autoplay>
            <div className="home-friendly-content-list" style={{ display: 'flex' }}>
              <div className="home-friendly-item">
                <a href="http://www.bjac.org.cn/" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>
                <div className="border-btm"></div>
              </div>
              <div className="home-friendly-item">
                <a href="http://www.accsh.org/index.php" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>
                <div className="border-btm"></div>
              </div>
              <div className="home-friendly-item home-friendly-border-right-none">
                <a href="http://www.tjac.org.cn/" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>
                <div className="border-btm"></div>
              </div>
              <div className="home-friendly-item ">
                <a href="http://www.hnlx.org.cn/" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>

              </div>
              <div className="home-friendly-item">
                <a href="http://www.cietac.org/" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>
              </div>
              <div className="home-friendly-item home-friendly-border-right-none">
                <a href="http://www.jnac.org.cn/" className="home-friendly-item-img" target="_blank" rel="noopener noreferrer"> </a>
              </div>

            </div>
          </Carousel>
        </div>
      </div>

      {/* 位置 */}
      <div className="home-adress">
        <div className="home-adress-content">
          <div className="adress-icon">
            <img className="img-quan" src={quanUrl} alt="" />
            <img className="img-point" src={pointUrl} alt="" />
            <span>长沙</span>
            <a style={{ lineHeight: '120px' }} href="http://api.map.baidu.com/geocoder?address=长沙市芙蓉中路二段80号顺天国际财富中心20楼&output=html&src=webapp.baidu.openAPIdemo" target="_blank" rel="noopener noreferrer">导航<i className="iconfont icon-dingwei"></i></a>
          </div>
          <div className="home-adress-info">
            <div className="adress-left">
              <p>邮编：410005</p>
              <p style={{display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start'}}>
                <span>现场立案咨询：</span>
                <div>
                  <p>0731-84427110</p>
                  <p>18942538908(邹秘书)</p>
                  <p>19310067804(李秘书)</p>
                </div>
              </p>
              <p>线上立案咨询：19119262115(邓秘书)</p>
              <p>档案室：0731-84896280</p>
              <p>办公室：0731-89872747</p>
              <p>信访室：0731-84896280</p>
              <p>网址：www.cscac.net</p>
              <p>传真：0731-84413393</p>
              <p>地址：长沙市芙蓉中路二段80号顺天国际财富中心20楼</p>
              {/* <p>地址：长沙市芙蓉中路二段80号顺天国际财富中心20楼(<a href="http://api.map.baidu.com/geocoder?address=长沙市芙蓉中路二段80号顺天国际财富中心20楼&output=html&src=webapp.baidu.openAPIdemo" target="_blank" rel="noopener noreferrer">导航<i className="iconfont icon-dingwei"></i></a>)</p> */}
            </div>
            <div className="adress-right">
              {/* <p>
                <img src={code2Url} alt="" />
                <span>长沙仲裁委员会服务号</span>
              </p> */}
              <p>
                <img src={code1Url} alt="" />
                <span>长沙仲裁委员会订阅号</span>
              </p>
            </div>
          </div>
        </div>

      </div>

      {/* 举报弹窗 */}
      <div className="report" style={{ display: this.state.showModal ? 'flex' : 'none' }}>
        <div className="report-header">
          <span></span><Icon type="close" onClick={this.closeModal.bind(this)} />
        </div>
        <div className="report-content">
          <a style={{ color: '#40a9ff' }} href='https://wfblxx.changsha.cn/' target="_blank">长沙市互联网违法和不良信息举报中心</a>
        </div>
      </div>
      <Footer></Footer>
    </div>
  }
}
export default Home
