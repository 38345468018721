import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import store from '../../redux/store'
import './index.css'
class SideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navState: '',
      sideTitle: "关于我们",
      enSideTitle: 'Elegant demeanour',
      sideList: [
        {
          title: '关于我们',
          enTitle: 'About Us',
          link: ''
        }
      ]
    }
  }
  componentDidMount() {
    store.subscribe(() => {
      this.setState({
        navState: store.getState().navState
      })
    })
    this.setState({
      sideTitle: this.props.title,
      enSideTitle: this.props.enTitle,
      sideList: this.props.list
    })
  }
  renderItem() {
    let list = this.state.sideList;
    return list.map((item, index) => {
      if(item.href) {
        return <div key={index} className='side-item'>
          <a href={item.href} target="_blank" rel="noopener noreferrer">
            <span>{item.title}</span>
            <span>{item.enTitle}</span>
          </a>
        </div>
      }
      return <div key={index} className={this.state.navState === item.title ? 'side-item selected' : 'side-item'}>
        <NavLink to={item.link} activeClassName="selected">
          <span>{item.title}</span>
          <span>{item.enTitle}</span>
        </NavLink>
      </div>
    })
  }
  render() {
    return <div id="side-bar">
      <div className="side-header">
        <div className="side-header-top">
          <p>
            <span className="white-border"></span>
          </p>
          <p>{this.state.sideTitle}</p>
        </div>
        <p>{this.state.enSideTitle}</p>
      </div>
      <div className="side-list">
        {this.renderItem()}
      </div>
    </div>
  }
}
export default SideBar