import React, { Component } from 'react';
import Banner from '../../components/Banner/index';
import SideBar from '../../components/SideBar/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Route } from 'react-router-dom';
import Laws from '../../components/Information/Laws';
import Case from '../../components/Information/Case';
import Research from '../../components/Information/Research';
import Changsha from '../../components/Information/Changsha';
import Historical from '../../components/Information/Historical';
import Details from '../../components/Details'
import './index.css'
import bannerImg from '../../assets/images/zczl.jpg'
// import NewsDetails from '../NewsDetails/index';
class Information extends Component {
  state={
    sideTitle: "仲裁资料",
    enSideTitle: 'Arbitration information',
    sideList: [
      {
        title: '法律法规',
        enTitle: 'Laws and regulations',          
        link: '/Information/laws'
      },
      {
        title: '案例专栏',
        enTitle: 'Case column',
        link: '/Information/case'
      },
      {
        title: '仲裁研究',
        enTitle: 'Research on Commercial Arbitration',
        link: '/Information/research'
      },
      {
        title: '电子期刊',
        enTitle: 'Electronic periodicals',
        link: '/Information/changsha'
      },
      {
        title: '历史资料',
        enTitle: 'Historical data',
        link: '/Information/historical'
      }
    ]
  }
  componentDidMount() {

  }
  renderScrollComponent() {
    if(this.props.location.query) {
      return <Route path="/Information/laws" render={() => (
        <Laws isScroll={this.props.location.query.isScroll}/>
      )}></Route>
    }
    return <Route path="/Information/laws" component={Laws}></Route> 
    
  }
  render() {
    return <div id="information">
      <Header currentPage="/Information"></Header>
      <Banner bannerTitle="仲裁资料" bannerEnTitle="Arbitration information" type="7"></Banner>
      <div className="content-box">
        <div className="content">
          <SideBar title={this.state.sideTitle} enTitle={this.state.enSideTitle} list={this.state.sideList}></SideBar>
          <Route exact path="/Information" component={Laws} ></Route>
          {/* <Route path="/Information/laws" component={Laws} ></Route> */}
          {this.renderScrollComponent()}
          <Route path="/Information/case" component={Case} ></Route>
          <Route path="/Information/research" component={Research} ></Route>
          <Route path="/Information/changsha" component={Changsha} ></Route>
          <Route path="/Information/historical" component={Historical} ></Route>
          <Route path="/Information/Details" component={Details} ></Route>
        </div>
      </div>   
      <Footer></Footer>     
    </div>
  }
}
export default Information