import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.css'
class Footer extends Component {
  componentDidMount() {

  }
  render() {
    return <div id="footer">
      <div className="footer-top">
        <div className="footer-top-content">
          <div>
            <p>长仲风采</p>
            <span><Link to="/Mien/aboutUs">关于我们</Link></span>
            <span><Link to="/Mien/development">发展历程</Link></span>
            <span><Link to="/Mien/organize">组织机构</Link></span>
            <span><Link to="/Mien/member">委员会成员</Link></span>
            <span><Link to="/Mien/special">专门委员会</Link></span>
            {/* <span><Link to="/Mien/tables">首席专家库</Link></span> */}
            <span><Link to="/Mien/culture">机构文化</Link></span>
            <span><Link to="/Mien/newslist">仲裁资讯</Link></span>
            {/* <span><Link to="/Mien/notice">长仲公告</Link></span> */}
          </div>
          <div>
            <p>仲裁指南</p>
            <span><Link to="/Guide/guideParties">当事人仲裁指南</Link></span>
            <span><Link to="/Guide/rules">仲裁规则</Link></span>
            <span><Link to="/Guide/process">仲裁流程</Link></span>
            <span><Link to="/Guide/charge">仲裁收费</Link></span>
            <span><Link to="/Guide/downFile">文书下载</Link></span>
            <span><Link to="/Guide/contracts">合同下载</Link></span>
            <span><Link to="/Guide/question">常见问题</Link></span>
          </div>
          <div>
            <p>仲裁员</p>
            <span><Link to="/Arbitrator/standard">仲裁员管理规范</Link></span>
            <span><Link to="/Arbitrator/service">服务仲裁员</Link></span>
            <span><Link to="/Arbitrator/list">仲裁员名册</Link></span>
            <span><Link to="/Arbitrator/appearance">仲裁员风采</Link></span>
            <span><Link to="/Arbitrator/train">仲裁员培训</Link></span>

            <span><Link to="/Arbitrator/application">申请仲裁员</Link></span>
            {/* <span><Link to="/Arbitrator/registration">仲裁员年度注册</Link></span> */}
            <span><Link to="/Arbitrator/handle">仲裁员在线办案</Link></span>
          </div>
          <div>
            <p>在线服务</p>
            <span><Link to="/FastBooting/Guide">申请仲裁指南</Link></span>
            <span><Link to="/FastBooting/specialist">申请专家</Link></span>
            <span><Link to="/FastBooting/ChargeFastCalculate">费用快算</Link></span>
            <span><Link to="/FastBooting/Complaints">反馈投诉</Link></span>
            <span><Link to="/FastBooting/JoinUs">加入我们</Link></span>
          </div>
          <div>
            <p>仲裁鉴定</p>
            <span><Link to="/CheckUp/management">鉴定工作管理制度</Link></span>
            <span><Link to="/CheckUp/guide">鉴定工作指引</Link></span>
            <span><Link to="/CheckUp/library">鉴定机构库</Link></span>
            <span><Link to="/CheckUp/declare">鉴定机构申报</Link></span>
            <span><Link to="/CheckUp/review">鉴定机构年审</Link></span>
          </div>
          <div>
            <p>仲裁资料</p>
            <span><Link to="/Information/laws">法律法规</Link></span>
            <span><Link to="/Information/case">案例专栏</Link></span>
            <span><Link to="/Information/research">仲裁研究</Link></span>
            <span><Link to="/Information/changsha">电子期刊</Link></span>
            <span><Link to="/Information/historical">历史资料</Link></span>
          </div>
          <div>
            <p>分支机构</p>
            <span><Link to="/Branch/finance">金融仲裁院</Link></span>
            <span><Link to="/Branch/television">影视文化仲裁院</Link></span>
            <span><Link to="/Branch/development">建设工程仲裁院</Link></span>
            <span><Link to="/Branch/international">中国长沙国际仲裁院</Link></span>         
            <span><Link to="/Branch/television">知识产权仲裁院</Link></span>
          </div>
          <div>
            <p >基层党建</p>
            <span><Link to="/Basic/introduction">秘书处党支部介绍</Link></span>
            <span><Link to="/Basic/building">基层党建工作动态</Link></span>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p style={{flex:1}}>版权所有：长沙仲裁委员会所刊载的所有内容，包括长沙仲裁委员会门户网站文字报道、图片、图表、标志、标识、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及为注册用户提供的所有信息，为长沙仲裁委员会门户网站及相关权利人所有。未经长沙仲裁委员会门户网站的书面授权，任何人不得转载、复制、改动、播送、展示。</p>
          <p><a target="_blank" href="https://beian.miit.gov.cn/" rel="noopener noreferrer" style={{color: '#fff'}}>湘ICP备18004845号-1</a></p>
          <p>技术支持：共道网络科技有限公司</p>
          </div>
      </div>
    </div>
  }
}
export default Footer