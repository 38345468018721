// import React, { Component } from 'react';
// import './index.css'
// import { Link } from 'react-router-dom';
// import { Pagination, Button, Input, Icon, message, Progress, Spin } from 'antd';
// import store from '../../../redux/store'

// class App extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       pageSize: 4,
//       total: 5,
//       pageNum: 1,
//       list: [],
//       uploadProgress: 0,
//       closeModal: false,//提交简历弹窗
//       file: '',//上传的文件
//       introduce: '',//选择上传文件名称
//       isChooseFile: false,//是否选择了上传文件
//       fileAddress: '',//文件上传成功后的地址,
//       success: false,
//       loading: false,
//       modalLoading: false,
//       imgCode: '',
//       imgCodeFlag: false,
//       btnLoading: false
//     }
//   }

//   getData(pageSize, page) {
//     this.setState({
//       loading: true
//     })
//     fetch(' /zhongcai/offer/?pid=1&sid=15&pageSize=' + pageSize + '&pageNum=' + page, {
//       method: "GET"
//     }).then((res) => {
//       if (res.status >= 500) {
//         message.error('服务器出错，请稍后重试', 3)
//         return false
//       }
//       return res.json();
//     }).then(res => {
//       if (!res) {
//         return
//       }
//       let data = res.data.pageInfo
//       if (data.list.length !== 0) {
//         this.setState({
//           list: data.list,
//           loading: false,
//           total: data.total,
//           resume: ''
//         })
//       } else {
//         message.warning("暂无数据，请前往后台添加数据后刷新", 3)
//         this.setState({
//           loading: false,
//           total: 0
//         })
//       }
//     })
//   }
//   onChange(page, pageSize) {
//     this.getData(pageSize, page)
//   }

//   componentDidMount() {
//     this.getData(this.state.pageSize, this.state.pageNum)
//     store.dispatch({
//       type: 'CHANGE_NAV',
//       payload: '加入我们'
//     })
//     let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
//     setTimeout(() => {
//       document.documentElement.scrollTop = h
//     }, 0)
//     this.getImgCode()
//   }
//   //获取图形验证码
//   getImgCode() {
//     this.setState({
//       btnLoading: true
//     })
//     fetch('/zhongcai/image/getVerify', {//通过pid以及sid获取到当前类目的id  
//       method: "POST",
//       headers: new Headers({
//         'Content-Type': 'application/json'
//       })
//     }).then((res) => {
//       if (res >= 500) {
//         message.error('服务器出错，获取验证码失败', 3)
//         this.setState({
//           btnLoading: false
//         })
//       }
//       return res.blob()
//     }).then(res => {
//       // console.log(res.toString())
//       this.setState({
//         btnLoading: false
//       })
//       let fr = new FileReader();
//       fr.onload = (e) => {
//         this.setState({
//           imgCode: e.target.result
//         })
//       }
//       fr.readAsDataURL(res);
//     })
//       .catch(err => {
//         message.error('请求异常，获取验证码失败', 3)
//         this.setState({
//           btnLoading: false
//         })
//       })
//   }

//   renderItem() {
//     let list = this.state.list;
//     return list.map((item, index) => {
//       return <Link to={
//         {
//           pathname: '/FastBooting/joinUsDetails/'+ item.id,
//           state: {
//             id: item.id
//           }
//         }
//       }>
//         <div id="item-box">
//           <span className="item-pointer"></span>
//           <li className="liInfo">{item.post || '关于“长沙仲裁委员会优秀仲裁员”表彰对象名单公示'}</li>
//           <li className='infoTime'>{item.createat.split(' ')[0] || '2018-11-21'}</li>
//         </div>
//       </Link>
//     })
//     // return list.map((item, index) => {
//     //   return <div className="myjob" key={index}>
//     //       <p className="post">{item.post}</p>
//     //       <p className="require">职位要求</p> 
//     //       {/* <ul className="requireList" dangerouslySetInnerHTML = {{ __html: item.description }}>
//     //       </ul> */}
//     //       <div><pre>{item.description}</pre></div>
//     //       {/* <textarea value={item.description} disabled="disabled" style={{resize: "none", background: "#fff", border: "none"}}></textarea> */}
//     //       <p className='recruit'>招聘人数: {item.number} <Button type="primary" onClick={this.showModal.bind(this)}>我要应聘</Button></p>         
//     //   </div>
//     // })



//   }

//   showModal() {
//     this.setState({
//       closeModal: true
//     })

//   }

//   fetchProgress(url, opts = {}, onProgress) {
//     return new Promise((resolve, reject) => {
//       var xhr = new XMLHttpRequest();
//       xhr.open(opts.method || 'get', url);
//       for (var key in opts.headers || {}) {
//         xhr.setRequestHeader(key, opts.headers[key]);
//       }

//       xhr.onload = e => resolve(e.target.responseText)
//       xhr.onerror = reject;
//       if (xhr.upload && onProgress) {
//         xhr.upload.onprogress = onProgress; //上传
//       }
//       if (xhr.onerror) {
//         xhr.onerror = (e) => {
//           message.error('上传失败:' + e, 4)
//         }
//       }
//       if ('onprogerss' in xhr && onProgress) {
//         xhr.onprogress = onProgress; //下载
//       }
//       xhr.send(opts.body)
//     })
//   }

//   fileChange(e) {
//     // let input = document.querySelector('#file')
//     if (e.target.files.length !== 0) {
//       var file = e.target.files[0];
//       var formData = new FormData();
//       formData.append('file', file)
//       let fileSize = file.size
//       if (fileSize > 1024 * 1024 * 5) {
//         e.target.value = ''
//         message.error("文件过大，请选择不大于5M的简历附件", 3)
//         return false
//       }
//       this.setState({
//         file: formData,
//         introduce: file.name,
//         isChooseFile: true
//       })
//     }
//   }

//   //关闭弹窗
//   closeModal() {
//     this.setState({
//       closeModal: false,
//       uploadProgress: 0,
//       success: false,
//       fileAddress: '',
//       isChooseFile: false
//     })
//     this.cancelChoose();
//     this.refs['name'].input.value = '';
//     this.refs['concat'].input.value = '';
//   }

//   uploadFile() {
//     if (!this.state.file) {
//       message.error('请选择简历附件再上传！', 3)
//       return
//     }
//     this.fetchProgress('/zhongcai/oss/uploadFile', {
//       method: 'POST',
//       body: this.state.file,
//     }, (e) => {
//       this.setState({
//         uploadProgress: parseInt(((e.loaded / e.total) * 90).toFixed(0))
//       })
//     }).then(res => {
//       this.setState({
//         uploadProgress: 100
//       })
//       this.setState({
//         fileAddress: JSON.parse(res).data.fileUrl
//       })
//     })
//   }
//   // 删除已经上传的文件
//   delUpload() {
//     this.setState({
//       uploadProgress: 0
//     })
//     this.cancelChoose()
//   }
//   //取消选择的上传文件
//   cancelChoose() {
//     this.setState({
//       isChooseFile: false,
//       file: '',
//       introduce: '',
//       fileAddress: ''
//     })
//   }

//   //换一组图形验证码
//   changeCode() {
//     this.getImgCode()
//   }
//   //验证验证码是否正确
//   checkCode() {
//     if (!this.refs['code-input'].input.value) {
//       return
//     }
//     let codeValue = this.refs['code-input'].input.value
//     fetch(`/zhongcai/image/checkVerify?ver=${codeValue}`)
//       .then(res => {
//         return res.json()
//       }).then(res => {
//         if (!res) {
//           message.error('验证码输入错误,请重试', 3)
//           this.setState({
//             imgCodeFlag: false
//           })
//           return
//         }
//         this.setState({
//           imgCodeFlag: true
//         })
//       })
//   }

//   // 提交应聘信息
//   submit() {
//     let name = this.refs['name'].input.value
//     let phone = this.refs['concat'].input.value
//     if (!name) {
//       message.error('请输入用户名', 3)
//       return
//     }
//     if (!phone) {
//       message.error('请输入联系方式', 3)
//       return
//     }
//     if (!this.state.fileAddress) {
//       message.error('请先上传简历附件', 3)
//       return
//     }
//     if (!this.refs['code-input'].input.value) {
//       message.error('请输入验证码', 3)
//       return
//     }
//     if (!this.state.imgCodeFlag) {
//       message.error('验证码输入错误，请重新输入', 3)
//       return
//     }
//     this.setState({
//       modalLoading: true
//     })
//     let json = {
//       name,
//       phone,
//       resume: this.state.fileAddress
//     }
//     fetch('/zhongcai/apply_job', {
//       method: 'POST',
//       body: JSON.stringify(json),
//       headers: new Headers({
//         'Content-Type': 'application/json'
//       })
//     }).then(res => {
//       if (res >= 500) {
//         message.error('服务器故障，请稍后重试', 4)
//         return false
//       }
//       return res.json()
//     }).then(res => {
//       if (!res) {
//         return
//       }
//       this.setState({
//         success: true,
//         modalLoading: false
//       })
//       message.success('提交应聘信息成功', 3)
//       setTimeout(() => {
//         this.closeModal()
//       }, 2000)
//     }).catch(e => {
//       message.error('网络错误,请检查您的网络', 4)
//     })
//   }

//   render() {
//     return <Spin spinning={this.state.loading} delay={500}>
//       <div id="joinUs" ref="ele">
//         {this.renderItem()}
//         <div className='pagination'>
//           <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
//         </div>
//         <div className="modal-box" style={{ display: this.state.closeModal ? 'flex' : 'none' }}>
//           <div className="modal-content">
//             <div className="modal-top">我要应聘</div>
//             <div className="modal-btm">
//               <div className="modal-item">
//                 <span>姓名<i></i></span>
//                 <Input placeholder="请输入您的姓名" ref='name' />
//               </div>
//               <div className="modal-item">
//                 <span>联系电话<i></i></span>
//                 <Input placeholder="请输入您的联系电话" ref='concat' />
//               </div>
//               <div className="modal-item">
//                 <span>上传简历<i></i></span>
//                 <div className='upload-content'>
//                   <Progress style={{ display: this.state.uploadProgress ? 'block' : 'none' }} type="circle" percent={this.state.uploadProgress} />
//                   <label htmlFor="file">
//                     <Icon type="plus" />
//                   </label>
//                   <input id="file" type="file" ref="fileInput" accept=".xls,.doc,.docx,.txt,.pdf" onChange={this.fileChange.bind(this)} />
//                   <div className="upload-tip">
//                     <span>（word、pdf格式）</span>
//                     <Button type="primary" style={{ display: this.state.uploadProgress === 100 ? 'none' : 'inline-block' }} icon="upload" onClick={this.uploadFile.bind(this)}>上传</Button>
//                     <Button type="primary" style={{ display: this.state.uploadProgress === 100 ? 'inline-block' : 'none' }} icon="delete" onClick={this.delUpload.bind(this)}>删除附件</Button>
//                   </div>
//                   <span className="preview-upload" style={{ display: this.state.isChooseFile ? 'flex' : 'none' }}>
//                     <span>{this.state.introduce}</span>
//                     <Icon type="close" onClick={this.cancelChoose.bind(this)} />
//                   </span>
//                 </div>
//               </div>
//               <div className="modal-item code-box">
//                 <span>验证码<i></i></span>
//                 <div>
//                   <Input ref="code-input" onBlur={this.checkCode.bind(this)} />
//                   {/* <span>{this.state.checkCode}</span> */}
//                   <img src={this.state.imgCode} style={{ width: '95px', height: '25px' }} />
//                   <Button type="primary" onClick={this.changeCode.bind(this)} loading={this.state.btnLoading}>换一组</Button>
//                 </div>
//               </div>
//               <div className="modal-item">
//                 <Button type="primary" onClick={this.submit.bind(this)} loading={this.state.modalLoading}>提交</Button>
//                 <Button onClick={this.closeModal.bind(this)}>返回</Button>
//               </div>
//               <p style={{ display: this.state.success ? 'block' : 'none' }}>提交完成,我们会与您取得联系</p>
//             </div>
//           </div>
//         </div>

//       </div>
//     </Spin>
//   }
// }
// export default App

import React, { Component } from 'react';
import { Pagination } from 'antd';
import MenuItem from '../../MenuItem'
import './index.css';
import '../../../assets/common.css'
import { Spin, message } from 'antd';
import store from '../../../redux/store'
class JoinUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: true,
      pageSize: 5,
      total: 5,
      pageNum: 1
    }
  }
  getData(pageSize, page) {
    fetch('/zhongcai/information/searchShow/?pid=1&sid=49&pageSize=' + pageSize + '&pageNum=' + page,{
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return false;
      }
      let data = res.data.pageInfo
      if(data.list.length !== 0) {
        this.setState({
          list: data.list,
          loading: false,
          total: data.total
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false,
          total: 0
        })
      }
    })
  }
  componentDidMount() {
    this.getData(this.state.pageSize, this.state.pageNum)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '加入我们'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    setTimeout(() => {
      document.documentElement.scrollTop = h
    }, 0)
  }
  renderItem() {
    let list = this.state.list;
    return list.map((item, index) => {
      return <MenuItem key={index} content={item.title} time={item.createat} urlId={item.id} dir="/FastBooting"></MenuItem>
    })
  }
  onChange(page, pageSize) {
    this.getData(pageSize, page)
  }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id="guide" ref="ele">
        <div className="allSameBox">
          {this.renderItem()}
        </div>
        <div className='pagination' id="common">
          <Pagination defaultCurrent={1} pageSize={this.state.pageSize} total={this.state.total} onChange={this.onChange.bind(this)} ></Pagination>
        </div>
      </div>
    </Spin>
  }
}
export default JoinUs