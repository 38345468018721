import React, { Component } from 'react';
import './index.css';
import Details from '../../Details'
import { Spin, message } from 'antd';
import store from '../../../redux/store'
class AboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      time: '',
      content: '',
      loading: true,
      source: ''
    }
  }
  componentDidMount() {
    console.log(this.props.isScroll)
    this.getData()
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '申请仲裁员'
    })
    let h = this.refs['ele'].getBoundingClientRect().top + document.documentElement.scrollTop
    if(this.props.isScroll === false) {

    } else {
      setTimeout(() => {
        document.documentElement.scrollTop = h;
      }, 0)
    }

    
  }
  getData() {
    fetch('/zhongcai/information/?pid=3&sid=46', {//通过pid以及sid获取到当前类目的id  
      method:"GET",
      headers: new Headers({
        'requestType': 'official_website'
      })
    }).then((res)=>{
      if(res.status >= 500) {
        message.error('服务器出错，请稍后重试', 3)
        return false
      }
      return res.json();
    }).then(res => {
      if(!res) {
        return
      }
      let list = res.data.pageInfo.list;
      if(list.length !== 0) {
        let id = res.data.pageInfo.list[0].id
        id && fetch('/zhongcai/information/' + id, {
          method:"GET",
          headers: new Headers({
            'requestType': 'official_website'
          })
        }).then((res)=>{
          // console.log(res)
          return res.json();
        }).then(res => {
          let data = res.data.information
          if(data.shows === 1) {
            message.error("该数据已被管理员修改为不展示！", 5);
            this.setState({
              loading: false
            })
            return false
          }
          this.setState({
            title: data.title,
            time: data.createat,
            content: data.content,
            source: data.source,
            loading: false
          })
        })
      } else {
        message.warning("暂无数据，请前往后台添加数据后刷新", 3)
        this.setState({
          loading: false
        })
      }     
    })
  }
  render() {
    return <Spin spinning={this.state.loading} size="large">
      <div id='about-us' ref="ele">        
        {this.state.title && <Details title={this.state.title} time={this.state.time} content={this.state.content} source={this.state.source}></Details>}
      </div>
    </Spin>
  }
}
export default AboutUs