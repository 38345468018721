import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './index.css'

class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsId: null
    }
  }
  componentDidMount() {
    this.props.urlId && this.setState({
      paramsId: this.props.urlId
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      paramsId: nextProps.urlId
    })
  }

  baidu(e) {
    let _hmt = _hmt || [];
    _hmt.push(['_trackEvent', '文章点击数', 'click']);
  }

  render() {
    return <Link to={
      {
        pathname: this.props.page ? this.props.page : this.props.dir + "/Details/" + this.state.paramsId,
        state: {
          id: this.state.paramsId
        }
      }
    } target="_blank">
      <div id="item-box" onClick={this.baidu.bind(this)} ref={elem => this.dEle = elem}>
        {/* <img src={Litop} className="allSameImg" alt=''></img> */}
        <span className="item-pointer"></span>
        <li className="liInfo">{this.props.content || '关于“长沙仲裁委员会优秀仲裁员”表彰对象名单公示'}</li>
        <li className='infoTime'>{this.props.time.split(' ')[0] || '2018-11-21'}</li>
      </div>
    </Link>
  }
}
export default MenuItem