import React, { Component } from 'react'
import { message } from 'antd'
import store from '../../../redux/store'
class Specialist extends Component {
  componentDidMount() {
    message.info('暂未开放！敬请期待！', 3)
    store.dispatch({
      type: 'CHANGE_NAV',
      payload: '申请专家'
    })
  }
  render() {
    return <div></div>
  }
}
export default Specialist